import { EDUCATORS_BASE_URL } from "../../utils/utils";

export const getEducatorWiseFilter = (params) => {
    const { pageNo, fType, isSlug, slug } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    const fUrl = (fType!=='states' && fType!=='cities' && fType!=='localities' && fType!=='exams' && fType!=='types' )?`${EDUCATORS_BASE_URL}educators/${fType}?page=${pageNo}`
    : fType == 'states' ? `${EDUCATORS_BASE_URL}educators/top-10-state-wise-educators?page=${pageNo}`
        : fType == 'cities' ? `${EDUCATORS_BASE_URL}educators/top-10-city-wise-educators?page=${pageNo}`
        : fType == 'localities' ? `${EDUCATORS_BASE_URL}educators/top-10-locality-wise-educators?page=${pageNo}`
        : fType == 'types' ? `${EDUCATORS_BASE_URL}educators/top-10-different-educators-type?page=${pageNo}`:null;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};


export const getEducatorsByTypeWise = (params) => {
    const { pageNo, fType, typeWiseSlug } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let requestOptions = {
        method: "GET",
        headers: myHeaders
    };
    const fUrl = fType == 'streams' ? `${EDUCATORS_BASE_URL}educators/stream/${typeWiseSlug}?page=${pageNo}`
        : fType == 'specializations' ? `${EDUCATORS_BASE_URL}educators/specializations/${typeWiseSlug}?page=${pageNo}`
        : fType == 'states' ? `${EDUCATORS_BASE_URL}educators/state/${typeWiseSlug}?page=${pageNo}`
        : fType == 'cities' ? `${EDUCATORS_BASE_URL}educators/city/${typeWiseSlug}?page=${pageNo}`
        : fType == 'localities' ? `${EDUCATORS_BASE_URL}educators/locality/${typeWiseSlug}?page=${pageNo}`
        : fType == 'types' ? `${EDUCATORS_BASE_URL}educators/type/${typeWiseSlug}?page=${pageNo}`
        : fType == 'exams' ? `${EDUCATORS_BASE_URL}educators/exams/${typeWiseSlug}?page=${pageNo}`
        : fType == 'courses' ? `${EDUCATORS_BASE_URL}educators/course-educators/${typeWiseSlug}?page=${pageNo}`
        : fType == 'admissions' ? `${EDUCATORS_BASE_URL}educators/base-course-educators/${typeWiseSlug}?page=${pageNo}`:null;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};


export const getEducatorDetail = (params) => {
    const { educatorId, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    let requestOptions = {
        method: "GET",
        headers: myHeaders
    };
    const fUrl =  `${EDUCATORS_BASE_URL}educators/university/${educatorId}`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getCourseDetail = (params) => {
    const { educatorSlug, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    let requestOptions = {
        method: "GET",
        headers: myHeaders
    };
    const fUrl =  `${EDUCATORS_BASE_URL}educators/courses/${educatorSlug}`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};


export const writeReviewEducator = (params) => {
    const { educator_id, reviews, rating, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("educator_id", educator_id);
    formData.append("reviews", reviews);
    formData.append("rating", rating);
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:formData
    };
    const fUrl =  `${EDUCATORS_BASE_URL}educators/add-review`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const reportAgainstEducator = (params) => {
    const { educator_id, name, email, report, token } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("educator_id", educator_id);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("report", report);
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:formData
    };
    const fUrl =  `${EDUCATORS_BASE_URL}educators/report`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const followEducator = (params) => {
    const { educator_id, status, token } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("educator_id", educator_id);
    formData.append("status", status);
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:formData
    };
    const fUrl =  `${EDUCATORS_BASE_URL}educators/follow`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const claimAccountEducator = (params) => {
    const { educator_id, name, email, phone , token, description } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    formData.append("educator_id", educator_id);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("description", description);
    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:formData
    };
    const fUrl =  `${EDUCATORS_BASE_URL}educators/claims`;
    return fetch(fUrl, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const educatorCommentAndReply = (params) => { 
    const { educator_id, comment, commentId, token } = params;
    
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    let formData = new FormData();
    formData.append('id', educator_id);
    formData.append('comment', comment);
    formData.append('comment_id', commentId || 0);
  
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${EDUCATORS_BASE_URL}educators/comment`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const bookMarkEducator = (params) => { 
    const { educatorId, favourite, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`)
    let formData = new FormData();
    formData.append('educator_id', educatorId);
    formData.append('action', favourite);
  
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${EDUCATORS_BASE_URL}educators/add-to-fav`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };

  export const getFavouritesEducators = (params) => { 
    const { pageNo, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${EDUCATORS_BASE_URL}educators/get-fav-educators?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const likeUnlikeDislike = (params) => {
    const { educatorId, voteType, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let formData = new FormData();
    // formData.append('user_id', user_id);
    formData.append('mod', 'educator');
    formData.append('action', voteType);
    formData.append('id', educatorId);
    formData.append('educator_id', educatorId);

    let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData
    };
    return fetch(`${EDUCATORS_BASE_URL}educators/like`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
};

export const getSearchedData = (params) => { 
    const { pageNo, keyword } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    let formData = new FormData();
    formData.append('keyword', keyword);
    // formData.append('type', type);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData
    };  
    return fetch(`${EDUCATORS_BASE_URL}educators/globle-search?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
  };