import React, { useEffect, useRef, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, yearSatra } from '../../../../utils/utils';

import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { useNavigate } from 'react-router-dom';
import TopicList from '../common/albumList';
import { getAllAlbums, getAllLyricists } from '../../../../api/lyrics';
import AlbumList from '../common/albumList';
import LyricistList from '../common/lyricistArtistList';
import LyricistArtistList from '../common/lyricistArtistList';


function LyricsLyricists() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allLyricsLyricists, setAllLyricsLyricists] = useState([]);
    const [totalLyricists, setTotalLyricists] = useState(0);
    const [allFilteredLyricsLyricists, setAllFilteredLyricsLyricists] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const searchBox = useRef(null);
    const navigation = useNavigate();   
    useEffect(() => {
        handleFetchAllLyricsLyricists(1);
    }, [])

    // fetch all lyrics lyricists
    const handleFetchAllLyricsLyricists = async (pageNo) => {
        const resAllLyricsLyricists = await getAllLyricists({ pageNo: pageNo });
        if (resAllLyricsLyricists?.success) {
            // console.log('albums', resAllLyricsLyricists);
            setTotalLyricists(resAllLyricsLyricists?.total)
            setIsNotFound(false)
            resAllLyricsLyricists?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resAllLyricsLyricists?.data?.length && pageNo == 1) {
                setAllLyricsLyricists(resAllLyricsLyricists?.data);
                setAllFilteredLyricsLyricists(resAllLyricsLyricists?.data);
            } else if (resAllLyricsLyricists?.data?.length && pageNo !== 1) {
                setAllLyricsLyricists([...allLyricsLyricists, ...resAllLyricsLyricists?.data]);
                setAllFilteredLyricsLyricists([...allLyricsLyricists, ...resAllLyricsLyricists?.data]);
            } else {
                if (pageNo == 1) {
                    setAllLyricsLyricists([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more quotes albums data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllLyricsLyricists(nxtPage);
        setPageNo(nxtPage);
        setIsSearch(false);
        searchBox.current.value = '';
    }

    const popularCareerCats = [
        {
            name: "Joyce Meyer",
            value: "Joyce Meyer",
            redirectUrl: "#"
        },
        {
            name: "Henry Rollins",
            value: "Henry Rollins",
            redirectUrl: "#"
        },
        {
            name: "Helen Mirren",
            value: "Helen Mirren",
            redirectUrl: "#"
        },
        {
            name: "Justin Trudeau",
            value: "Justin Trudeau",
            redirectUrl: "#"
        },
        {
            name: "Helen Fisher",
            value: "Helen Fisher",
            redirectUrl: "#"
        },
        {
            name: "Maya Angelou",
            value: "Maya Angelou",
            redirectUrl: "#"
        },
        {
            name: "Helen Keller",
            value: "Helen Keller",
            redirectUrl: "#"
        },
        {
            name: "Julie Sweet",
            value: "Julie Sweet",
            redirectUrl: "#"
        },
        {
            name: "More ...",
            value: "More ...",
            redirectUrl: "#"
        }
    ];
    

    const handleSearchLyricist = (e) => {
        const searchTxt = e.target.value;
        setSearchTerm(searchTxt);
        if (searchTxt == '') {
            setIsSearch(false);
            handleFetchAllLyricsLyricists(1);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            setIsSearch(true);
            searchLyricist(searchTxt);
        }
    }

    const searchLyricist = (searchTxt) =>{            
            const fLyricists = allLyricsLyricists?.filter((au, index)=>au.name.toLowerCase().includes(searchTxt.toLowerCase()) );
            setAllFilteredLyricsLyricists(fLyricists);
            setTotalRecordFound(fLyricists?.length);
    }

    return (<>
         <div className='d-flex align-items-center justify-content-between'>
            <BreadCrumbs />
            <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handleSearchLyricist} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" ref={searchBox} placeholder="Search Lyricist in List" />
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchLyricist(searchTerm)}></i>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    {
                        !isSearch?<><div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Top 10 Popular Lyricists</strong>`} subTitle={`Read most creative poetry used in songwriting by over ${ nFormatter(totalLyricists) } lyricists from around the world!`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></>:null
                    }
                    
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allFilteredLyricsLyricists?.map((lyricist, sIndex) => (
                                <LyricistArtistList laData={lyricist} key={sIndex} type="lyricist" mH={false} boxSize={'col-md-3'} redirectionFn={()=>navigation(`/lyrics/lyricist/${lyricist?.id}/${lyricist?.slug}`)}/>
                            ))
                        }
                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default LyricsLyricists;
