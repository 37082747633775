import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { useAuth } from '../../../../Context/AuthContext';
import * as Yup from "yup";
import {
    convertDateFormat, nFormatter, slugToNormal, modulesUrls,
    ERROR_SOMETHING_WENT_WRONG,
    placeholderImages,
    CDN_BASE_URL,
    convertDateFormatOnly,
    slugify
} from '../../../../utils/utils';

import Share from '../../../common/share';
import Modal from '../../../common/Modal';
import styles from "../../../../assets/css/styles/Home.module.css";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import { getTutorialDetails } from '../../../../api/tutorial';
import TutorialsComments from '../../../common/comments/tutorialsComments';
import PopularCareerCategories from '../../careernews/popularcategories';
import { bookMarkCorporate, claimAccountCompany, corporateCommentAndReply, followCompany, getCorporateCompanyDetail, likeUnlikeDislike, reportAgainstCompany, writeReviewCompany } from '../../../../api/corporate';
import StarRatings from 'react-star-ratings';
import { Formik } from 'formik';
import CorporateComments from '../../../common/comments/corporatesComments';

function CorporateDetail() {
    const { globalToken, user } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);    
    const[nextPrevLoader, setNextPrevLoader] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [upvoteBtnLoader, setUpvoteBtnLoader] = useState(false);
    const [downvoteBtnLoader, setDownvoteBtnLoader] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [tutorialDetailData, setTutorialDetailData] = useState([]);
    const [companySlug, setCompanySlug] = useState();
    const [isShareActive, setIsShareActive] = useState(false);
    const navigation = useNavigate();
    const params = useParams();
    const location = useLocation();
    const currentPath = location.pathname;
    const [introLoad, setIntroLoad] = useState(false);
    const [companyDetail, setCompanyDetail] = useState();
    const [isOpenRatingModal, setIsOpenRatingModal] = useState(false);
    const [isOpenReportModal, setIsOpenReportModal] = useState(false);
    const [isLocationChange, setIsLocationChange] = useState(false);
    const [isOpenClaimAccountModal, setIsOpenClaimAccountModal] = useState(false);
    const [ratingVal, setRatingVal] = useState();
    const DisplayingErrorMessagesSchemaRating = Yup.object().shape({
        rating: Yup.string().required("Rating is required"),
        reviews: Yup.string().required("Review is required")

    });
    const DisplayingErrorMessagesSchemaReport = Yup.object().shape({
        report: Yup.string().required("Report message is required")

    });
    const DisplayingErrorMessagesSchemaClaimAccount = Yup.object().shape({
        description: Yup.string().required("Message is required")

    });
    // Fetches corporate details when component mounts or params change
    useEffect(() => {
        if (params?.companySlug) {
            const  cId = (params?.companySlug).split('-').pop();
            setCompanySlug(cId);
            handleFetchCompanyDetail(cId);
        }
        
    }, []);

    useEffect(() => {
        if (params?.companySlug) {
            const  cId = (params?.companySlug).split('-').pop();
            setCompanySlug(cId);
            handleFetchCompanyDetail(cId);
        }

    }, [isLocationChange]);
    const changeRating = (newRating) => {
        setRatingVal(newRating);
      };

    // Handles fetching corporate detail
    const handleFetchCompanyDetail = async (cSlug) => {
        setIntroLoad(true);
        const resCompanyDetail = await getCorporateCompanyDetail({ token:globalToken, companySlug: cSlug });
        // console.log('company detail', resCompanyDetail);
        setLoading(false);
        setLoadingLoadMore(false);
        setUpvoteBtnLoader(false);
        setDownvoteBtnLoader(false)
        if (resCompanyDetail?.success) {
            setIsNotFound(false);
            if (resCompanyDetail) {
                setCompanyDetail(resCompanyDetail);
            } else {
                setCompanyDetail({});
                setIsNotFound(true);
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
        }
    }

    // Handles adding/removing corporate from favorites
    const FavouriteButton = ({ is_fav_camp }) => {
        const [favBtnLoading, setFavBtnLoading] = useState(false);

        const handleFavourite = async () => {
            setFavBtnLoading(true);
            const token = globalToken;
            const res = await bookMarkCorporate({ token: token, corporateId: companyDetail?.corporate?.id, favourite: is_fav_camp ? 'unfav' : 'fav' });
            if (res?.success) {
                handleFetchCompanyDetail(companyDetail?.corporate?.id);
            }
            setFavBtnLoading(false);
        }

        return (
            <button
                type="button"
                disabled={favBtnLoading}
                onClick={handleFavourite}
                className="btn bg-white p-0 d-flex btn-like-circular"
                style={{ border: 'none', boxShadow: 'none' }}
            >
                {favBtnLoading ? (
                    <div className="h-5 w-5">
                        <Loadingicon />
                    </div>
                ) : (
                    <>
                        {is_fav_camp ? <i className="fa fa-heart heart active"></i> : <i className="fa heart fa-heart"></i>}
                    </>
                )}
            </button>
        );
    }

    // Handles sharing the tutorial
    const InlineShare = ({ compData, isShareActive, setIsShareActive }) => {
        const handleShareClick = () => {
            setIsShareActive(!isShareActive);
        };
        return (
            <small className="position-relative" title="Share">
                {isShareActive ? (
                    <Share
                        shareContent={{
                            url: `${modulesUrls?.tuteehubCorporates}/company/${compData?.corporate?.slug}/${compData?.corporate?.company_reg_id}`,
                            quote: `${compData?.corporate?.corporate_data?.about}`,
                            hashing: `#tuteehub`,
                            title: `${compData?.corporate?.company_name}`,
                        }}
                        isCustomPos={true}
                    />
                ) : null}
                <span
                    className={`${styles.icons} ${styles.shareIco}`}
                    style={{ display: 'inline-block' }}
                    role="button"
                    onClick={handleShareClick}
                ></span>
            </small>
        );
    }

    // Handles upvoting or downvoting the corporate
    const handleLikeUnlikeDislike = async (voteType, type) => {
        if (type === 'dLike') setDownvoteBtnLoader(true);
        else setUpvoteBtnLoader(true);
        const resLikeUnlike = await likeUnlikeDislike({ token: globalToken, corporateId: companyDetail?.corporate?.id, voteType });
        if (resLikeUnlike?.success) {
            handleFetchCompanyDetail(companyDetail?.corporate?.id);
            toast.success(resLikeUnlike?.data?.message);
        }
    }

    // Handles submitting comments and replies
    const handleSubmitComment = async (commentId, setComment, comment, isReply, commentLoader, setIsReplyShow) => {
        commentLoader(true);
        try {
            const resCommentCommit = await corporateCommentAndReply({
                token: globalToken,
                corporate_id: companyDetail?.corporate?.id,
                commentId: isReply ? commentId : 0,
                comment,
            });
            if (resCommentCommit?.success) {
                setIsReplyShow(false);
                toast.success(resCommentCommit?.message);
                handleFetchCompanyDetail(companySlug);
            }
            setComment('');
        } catch (error) {
            toast.error('Error sending comment');
        } finally {
            commentLoader(false);
        }
    }

    // Modifies anchor tags to open in a new tab
    const modifyAnchorTags = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const anchorTags = doc.querySelectorAll('a');
        anchorTags.forEach(tag => {
            tag.setAttribute('target', '_blank');
        });
        return doc.documentElement.innerHTML;
    };



    // get all chapters list
    // const handleChaptersList = async (tId) =>{
    //     const resChaptersList = await getTutorialChaptersList({token:globalToken, tutorialId:tId})
    //     if(resChaptersList?.success){
    //         setTutorialChapters(resChaptersList?.data.reverse())
    //     }
    // }

    // get detail by chapter
    // const handleTutorialDetailByChapter = async (tId, cId) =>{
    //     setIntroLoad(false);
    //     const resDetailByChapter = await getTutorialDetailByChapter({token:globalToken, tutorialId:tId, chapterId:cId})
    //     if(resDetailByChapter?.success){
    //         // console.log(resDetailByChapter)
    //         setDynamicDesc(resDetailByChapter?.data?.description);
    //         setDynamicTitle(resDetailByChapter?.data?.title);
    //         // setTutorialChapters(resChaptersList?.data)
    //     }
    // }

    const openHideRatingModal = () =>{
        setIsOpenRatingModal(!isOpenRatingModal);
    }

    const openHideReportModal = () =>{
        setIsOpenReportModal(!isOpenReportModal);
    }

    const openHideClaimAccountModal = () =>{
        setIsOpenClaimAccountModal(!isOpenClaimAccountModal);
    }

    const handleReviewRating = async (values) =>{
        setBtnLoading(true);
        const resReviewRating = await writeReviewCompany({token:globalToken, corporate_id:companyDetail?.corporate?.id, reviews:values?.reviews, rating:values?.rating});
        // alert(JSON.stringify(resReviewRating))
        if(resReviewRating?.success){
            handleFetchCompanyDetail(companySlug);
            toast.success('Review & Rating given');
            openHideRatingModal(false);
        }else{
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setBtnLoading(false); 
    }

    const handleReport = async (values) =>{
        setBtnLoading(true);
        const resReviewRating = await reportAgainstCompany({name:user?.name, email:user?.email, token:globalToken, corporate_id:companyDetail?.corporate?.id, report:values?.report});
        // alert(JSON.stringify(resReviewRating))
        if(resReviewRating?.success){
            handleFetchCompanyDetail(companySlug);
            toast.success('Report registered');
            openHideReportModal(false);
        }else{
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setBtnLoading(false); 
    }

    const handleFollow = async (fStatus) =>{
        setBtnLoading(true);
        const resFollow = await followCompany({token:globalToken, corporate_id:companyDetail?.corporate?.id, status:fStatus});
        // alert(JSON.stringify(resReviewRating))
        if(resFollow?.status==1){
            handleFetchCompanyDetail(companySlug);
            toast.success(resFollow?.data?.message)
        }else{
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setBtnLoading(false); 
    }


    const handleClaimAccount= async (cValues) =>{
        setBtnLoading(true);
        const resClaimAccount = await claimAccountCompany({token:globalToken, corporate_id:companyDetail?.corporate?.id, name:user?.name, email:user?.email, phone:user?.phone, description:cValues?.description});
        // alert(JSON.stringify(resClaimAccount))
        // alert(JSON.stringify(resReviewRating))
        if(resClaimAccount?.success){
            openHideClaimAccountModal();
            handleFetchCompanyDetail(companySlug);
            toast.success('You claim to account submitted');
            
        }else{
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setBtnLoading(false); 
    }
    return (
        <>
            {loading ? (
                <div className="w-full flex items-center justify-center py-4 text-themecolor min-h-[400px] group-card card-body">
                    <div className="h-10 w-10 theme-color">
                        <Loadingicon />
                    </div>
                </div>
            ) : (
                <>
                    <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                        <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/corporates' style={{ fontSize: 14, textDecoration: 'none' }}>Corporates</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/corporates/industry/${companyDetail?.corporate?.industries[0]?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{companyDetail?.corporate?.industries[0]?.name}</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/corporates/state/${companyDetail?.corporate?.state?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{companyDetail?.corporate?.state?.name}</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to={`/corporates/city/${companyDetail?.corporate?.city?.slug}`} style={{ fontSize: 14, textDecoration: 'none' }}>{companyDetail?.corporate?.city?.name}</NavLink>
                            </li>
                            <li
                                className={`breadcrumb-item capitalize active clamping ellipsis`}
                            >
                                {slugToNormal(params.companySlug)}
                            </li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className={`col-md-12`}>
                            <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                                <div className="px-0">
                                    <div className="flex-column d-flex flex-md-row justify-content-between">

                                        <div className="mr-md-5 pr-md-5 flex-1">
                                            <h1 className="title-job d-flex align-items-center justify-content-between text-capitalize">
                                                <strong>{companyDetail?.corporate?.company_name}</strong>
                                                <span className="d-flex gap-3 align-items-center">
                                                <FavouriteButton  is_fav_camp={companyDetail?.isFavourite}/>
                                                
                                                    <InlineShare compData={companyDetail} isShareActive={isShareActive} setIsShareActive={setIsShareActive} />
                                                </span>
                                            </h1>
                                            <div className="d-flex mb-4 flex-md-row flex-column justify-content-between align-md-items-center">

                                                <span className="text-black-50 gig-table d-flex align-items-center">
                                                    <small style={{ marginTop: 1, float: 'left' }}>

                                                        <span className='star-container me-1'><span><i className='fa fa-star'></i>{companyDetail?.reviews?.avgRating || 0}</span> based on <strong>{companyDetail?.reviews?.rating || 0} Reviews</strong></span>
                                                        </small> 
                                                        <span className="big-dots"></span>
                                                        <small>{companyDetail?.corporate?.likes} up votes</small>
                                                            {
                                                                upvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                                                                <Loadingicon />
                                                            </span>:<><i
                                                                className={`fas fa-thumbs-up cursor-pointer ms-2 ${companyDetail?.corporate?.isLiked && "text-themecolor"
                                                                }`}
                                                                onClick={() =>
                                                                    handleLikeUnlikeDislike(companyDetail?.corporate?.isLiked ? "unlike" : "like",'lke')
                                                                }
                                                            ></i>{" "}</>
                                                            }
                                                        <span className="big-dots"></span>
                                                        <small>{companyDetail?.corporate?.dislikes} down votes</small>
                                                            {
                                                                downvoteBtnLoader?<span className="custom-loader-small ms-2 theme-color">
                                                                <Loadingicon />
                                                            </span>:<><i
                                                                className={`fas fa-thumbs-down cursor-pointer ms-2 ${companyDetail?.corporate?.isDisliked && "text-themecolor"
                                                                }`}
                                                                onClick={() =>
                                                                    handleLikeUnlikeDislike(companyDetail?.corporate?.isDisliked ? "rmdislike" : "dislike", 'dLike')
                                                                }
                                                            ></i>{" "}</>
                                                            }
                                                        <span className="big-dots"></span>
                                                    <small>Incorporation Date: {convertDateFormatOnly(companyDetail?.corporate?.company_incorporation_date)}</small>
                                                    <span className="big-dots"></span>
                                                    <small className={`${companyDetail?.corporate?.company_status=='Active'?'text-success':'text-danger'}`}><strong>{companyDetail?.corporate?.company_status}</strong></small>
                                                </span>
                                                {/* <span className="text-black-50">Published on {convertDateFormat(lyricsDetailData.posting_date)}</span> */}

                                            </div>
                                        

                                            <div id="questAns" className="w-100 rounded text-black-60 mt-4 leads cn-desc" dangerouslySetInnerHTML={{
                                                __html: modifyAnchorTags(`${companyDetail?.corporate?.corporate_data?.about}`)
                                            }}></div>
                                            <div className="w-100 rounded text-black-60 mt-2 border p-3">
                                                <div className='row-custom d-flex flex-md-row flex-column align-items-center justify-content-between mt-2 p-1'>
                                                    <div className='row-head'>
                                                        CIN
                                                    </div>
                                                    <div className='row-value color-gray'>
                                                        <strong>{companyDetail?.corporate?.company_reg_id}</strong>
                                                    </div>
                                                </div>
                                                <div className='row-custom d-flex flex-md-row flex-column align-items-center justify-content-between mt-2 bg-gray p-1'>
                                                    <div className='row-head'>
                                                        Email
                                                    </div>
                                                    <div className='row-value color-gray'>
                                                        <strong>{companyDetail?.corporate?.email || '--'}</strong>
                                                    </div>
                                                </div>
                                                <div className='row-custom d-flex flex-md-row flex-column align-items-center justify-content-between mt-2 p-1'>
                                                    <div className='row-head'>
                                                        Company Type
                                                    </div>
                                                    <div className='row-value color-gray'>
                                                    <Link to={`/corporates/type/${slugify(companyDetail?.corporate?.company_type)}`} className='color-blue-hover'><strong>{companyDetail?.corporate?.company_type || '--'}</strong></Link>
                                                    </div>
                                                </div>
                                                <div className='row-custom d-flex flex-md-row flex-column align-items-center justify-content-between mt-2 bg-gray p-1'>
                                                    <div className='row-head'>
                                                    Company Category | Company Subcategory
                                                    </div>
                                                    <div className='row-value color-gray'>
                                                        <strong>{companyDetail?.corporate?.corporate_data?.company_category?<>{companyDetail?.corporate?.corporate_data?.company_category} | {companyDetail?.corporate?.corporate_data?.company_sub_category}</>:'--'}</strong>
                                                    </div>
                                                </div>
                                                <div className='row-custom d-flex flex-md-row flex-column align-items-center justify-content-between mt-2 p-1'>
                                                    <div className='row-head'>
                                                    Company Incorporation Date
                                                    </div>
                                                    <div className='row-value color-gray'>
                                                        <strong>{convertDateFormatOnly(companyDetail?.corporate?.company_incorporation_date)}</strong>
                                                    </div>
                                                </div>
                                                <div className='row-custom d-flex flex-md-row flex-column align-items-center justify-content-between mt-2 bg-gray p-1'>
                                                    <div className='row-head'>
                                                    ROC
                                                    </div>
                                                    <div className='row-value color-gray'>
                                                        <strong>{companyDetail?.corporate?.corporate_data?.roc || '--'}</strong>
                                                    </div>
                                                </div>
                                                <div className='row-custom d-flex flex-md-row flex-column align-items-center justify-content-between mt-2 p-1'>
                                                    <div className='row-head'>
                                                    Authorized Capital
                                                    </div>
                                                    <div className='row-value color-gray'>
                                                        <strong>{companyDetail?.corporate?.corporate_data?.authorized_capital || '--'}</strong>
                                                    </div>
                                                </div>
                                                <div className='row-custom d-flex flex-md-row flex-column align-items-center justify-content-between mt-2 bg-gray p-1'>
                                                    <div className='row-head'>
                                                    Paid Up Capital
                                                    </div>
                                                    <div className='row-value color-gray'>
                                                        <strong>{companyDetail?.corporate?.corporate_data?.paid_up_capital || '--'}</strong>
                                                    </div>
                                                </div>
                                                <div className='row-custom d-flex flex-md-row flex-column align-items-center justify-content-between mt-2 p-1'>
                                                    <div className='row-head'>
                                                    Industries
                                                    </div>
                                                    <div className='row-value color-gray'>
                                                        <Link to={`/corporates/industry/${companyDetail?.corporate?.industries[0]?.slug}`} className='color-blue-hover'><strong>{companyDetail?.corporate?.industries[0]?.name || 'NA'}</strong></Link>
                                                    </div>
                                                </div>
                                                <div className='row-custom d-flex flex-md-row flex-column align-items-center justify-content-between mt-2 bg-gray p-1'>
                                                    <div className='row-head'>
                                                    Website
                                                    </div>
                                                    <div className='row-value color-gray'>
                                                        <strong>{companyDetail?.corporate?.corporate_data?.website || '--'}</strong>
                                                    </div>
                                                </div>
                                                <div className='row-custom d-flex flex-md-row flex-column align-items-center justify-content-between mt-2 p-1'>
                                                    <div className='row-head'>
                                                    Location
                                                    </div>
                                                    <div className='row-value color-gray'>
                                                        <strong>{companyDetail?.corporate?.corporate_data?.address || '--'}</strong>
                                                    </div>
                                                </div>
                                                <div className='row-custom d-flex flex-column align-items-start justify-content-start mt-3 bg-gray p-1'>
                                                    <div className='row-head'>
                                                    Directors
                                                    </div>
                                                    {/* <div className='row-value color-gray'>
                                                        {
                                                           JSON.parse(companyDetail?.corporate?.corporate_data?.directors)?.length? JSON.parse(companyDetail?.corporate?.corporate_data?.directors)?.map((dir, i)=><><strong>{dir['Director Name']}</strong>
                                                           {
                                                            i<JSON.parse(companyDetail?.corporate?.corporate_data?.directors)?.length -1?<span className="big-dots"></span>:null
                                                           }
                                                           </>):<strong>{'--'}</strong>
                                                        }
                                                        
                                                    </div> */}
                                                    <div className='row-value color-gray'>
                                                            {
                                                                companyDetail?.corporate?.corporate_data?.directors 
                                                                ? (() => {
                                                                    try {
                                                                        const directors = JSON.parse(companyDetail.corporate.corporate_data.directors);
                                                                        return directors.length 
                                                                            ? directors.map((dir, i) => (
                                                                                <React.Fragment key={i}>
                                                                                    <strong>{dir['Director Name']}</strong>
                                                                                    {i < directors.length - 1 && <span className="big-dots"></span>}
                                                                                </React.Fragment>
                                                                            )) 
                                                                            : <strong>{'--'}</strong>;
                                                                    } catch (error) {
                                                                        return <strong>{'Invalid JSON'}</strong>;
                                                                    }
                                                                })()
                                                                : <strong>{'--'}</strong>
                                                            }
                                                        </div>

                                                </div>
                                            </div>
                                            <div className="col-span-12 question-box question-box-card py-2 mt-3 mb-3 mb-1">

                                                <button
                                                    className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                                    onClick={() => openHideRatingModal()}
                                                >
                                                    Write A Review
                                                </button>
                                                <button
                                                    className="btn-soft mr-2 cursor-pointer hover:bg-themecolor hover:text-white"
                                                    onClick={() => openHideReportModal()}
                                                >
                                                    Report
                                                </button>
                                                <button
                                                    className={`btn-soft ${companyDetail?.is_follower?'bg-themecolor text-white':''} mr-2 cursor-pointer hover:bg-themecolor hover:text-white`}
                                                    onClick={() => handleFollow(companyDetail?.is_follower?'unfollow':'follow')}
                                                >
                                                    {companyDetail?.is_follower?'Following':'Follow'} ({companyDetail?.followers_count || 0})
                                                </button>
                                                <button
                                                    className="btn-soft claim-acc-btn mr-2 cursor-pointer hover:bg-themecolor hover:text-white float-right"
                                                    onClick={() => openHideClaimAccountModal()}
                                                >
                                                    Is this your company ? <span>Claim Account</span>
                                                </button>
                                            </div>
                                        </div>
                                        
                                      
                                    </div>

                                    
                                </div>
                            </div>
                            <div className="flex justify-between mt-4">
                                            <button id="nextprevbtn"
                                                className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                                style={{ backgroundColor: !companyDetail?.corporate?.prevUrl ? '#ccc' : '#ff7200' }}
                                                disabled={companyDetail?.corporate?.prevUrl?false:true}
                                                onClick={() => {navigation(`/corporates/company/${companyDetail?.corporate?.prevUrl?.slug +'-'+ companyDetail?.corporate?.prevUrl?.id}`);setIsLocationChange(!isLocationChange)}}
                                            >
                                                {
                                                nextPrevLoader?<div className="h-5 w-5 mr-2">
                                                <Loadingicon />
                                                </div>:<>Previous</>
                                                }
                                            
                                            </button>
                                            <button id="nextprevbtn"
                                                className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                                                style={{ backgroundColor: !companyDetail?.corporate?.nextUrl ? '#ccc' : '#ff7200' }}
                                                disabled={companyDetail?.corporate?.nextUrl?false:true}
                                                onClick={() => {navigation(`/corporates/company/${companyDetail?.corporate?.nextUrl?.slug +'-'+ companyDetail?.corporate?.nextUrl?.id}`); setIsLocationChange(!isLocationChange)}}
                                            >
                                                {
                                                nextPrevLoader?<div className="h-5 w-5 mr-2">
                                                <Loadingicon />
                                                </div>:<>Next</>
                                                }
                                            </button>
                                        </div>
                            {/* <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                            <div className="heading-main position-relative my-3 overflow-hidden">
                                <h5 className="text-left bg-white pr-3 b"><strong>Comments({tutorialDetailData?.comments_count})</strong></h5>
                                <hr className="heading-hr mb-0" />
                            </div>
                            <TutorialsComments cData={tutorialDetailData} handleSubmitComment={handleSubmitComment}/> ({companyDetail?.comments_count})
                        </div> */}
                        <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                            <div className="heading-main position-relative my-3 overflow-hidden">
                                <h5 className="text-left bg-white pr-3 b"><strong>Comments ({companyDetail?.commentCount})</strong></h5>
                                <hr className="heading-hr mb-0" />
                            </div>
                            <CorporateComments cData={companyDetail} handleSubmitComment={handleSubmitComment}/>
                        </div>
                        </div>


                        {/* <div className='col-md-12'>
            <div className="col-md-12 my-4 w-full  border-none child-menu   card-body">
            <SimilarNews newsSlug={tutorialDetailData?.slug} patternLinks={patternLinks} redirectionFn={()=>alert(1)} stripBottom={false}/>
          </div>
          </div> */}
                    </div>
                </>
            )}


            <Modal
                closeModal={openHideRatingModal}
                setIsOpen={setIsOpenRatingModal}
                isOpen={isOpenRatingModal}
                callBack={openHideRatingModal}
                title={'Write A Review'}
                modalFooter={true}
            >
              <Formik
                    enableReinitialize={true}
                    initialValues={{
                        reviews: null,
                        rating:null
                    }}
                    validationSchema={DisplayingErrorMessagesSchemaRating}
                    onSubmit={(values) => {
                        if (values){
                            handleReviewRating(values);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    }) => (
                        <form className="" onSubmit={handleSubmit}>
                           
                            <div className="mb-2">
                            <textarea
                                disabled={false}
                                type={'text'}
                                name={'reviews'}
                                autoComplete={'off'}
                                id={'reviews'}
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                placeholder={'Write A Review'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={5}
                                
                            // value={}
                            ></textarea>
                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                {touched['reviews'] && errors['reviews'] && errors['reviews']}
                            </div>
                            </div>
                            <div className="mb-3 text-center">
                            
                            <StarRatings
                                rating={ratingVal}
                                starRatedColor="#F08645"
                                starHoverColor="rgb(253 160 104)"
                                changeRating={(newRating)=>{changeRating(newRating);setFieldValue('rating', newRating)}}
                                numberOfStars={5}
                                starDimension="35px"
                                starSpacing="5px"
                                name="rating"
                                />
                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                {touched['rating'] && errors['rating'] && errors['rating']}
                            </div>
                            </div>
                            <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-5">
                                    <button
                                    type="button"
                                    className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                                    onClick={()=>{openHideRatingModal()}}
                                    >
                                    Cancel
                                    </button>
                                    <button
                                    type="submit"
                                    disabled={btnLoading}
                                    className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                                    // onClick={()=>handleAcceptRejectCancel()}
                                    
                                    >
                                    {btnLoading ? (
                <div className="h-5 w-5">
                    <Loadingicon />
                </div>
            ) : 'Submit'
        }
                                    </button>  
                            </div>
                        </form>
                    )}
                </Formik>
             
            </Modal>


            {/* modal for report */}


            <Modal
                closeModal={openHideReportModal}
                setIsOpen={setIsOpenReportModal}
                isOpen={isOpenReportModal}
                callBack={openHideReportModal}
                title={'Report Against Company'}
                modalFooter={true}
            >
              <Formik
                    enableReinitialize={true}
                    initialValues={{
                        report:null
                    }}
                    validationSchema={DisplayingErrorMessagesSchemaReport}
                    onSubmit={(values) => {
                        if (values){
                            handleReport(values);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    }) => (
                        <form className="" onSubmit={handleSubmit}>
                           
                            <div className="mb-2">
                            <textarea
                                disabled={false}
                                type={'text'}
                                name={'report'}
                                autoComplete={'off'}
                                id={'report'}
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                placeholder={'Write message'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={5}
                                
                            // value={}
                            ></textarea>
                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                {touched['report'] && errors['report'] && errors['report']}
                            </div>
                            </div>
                            
                            <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-5">
                                    <button
                                    type="button"
                                    className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                                    onClick={()=>{openHideReportModal()}}
                                    >
                                    Cancel
                                    </button>
                                    <button
                                    type="submit"
                                    disabled={btnLoading}
                                    className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                                    // onClick={()=>handleAcceptRejectCancel()}
                                    
                                    >
                                    {btnLoading ? (
                <div className="h-5 w-5">
                    <Loadingicon />
                </div>
            ) : 'Submit'
        }
                                    </button>  
                            </div>
                        </form>
                    )}
                </Formik>
             
            </Modal>

            {/* modal for claim account */}
            <Modal
                closeModal={openHideClaimAccountModal}
                setIsOpen={setIsOpenClaimAccountModal}
                isOpen={isOpenClaimAccountModal}
                callBack={openHideClaimAccountModal}
                title={'Claim Account?'}
                modalFooter={true}
            >
              <Formik
                    enableReinitialize={true}
                    initialValues={{
                        report:null
                    }}
                    validationSchema={DisplayingErrorMessagesSchemaClaimAccount}
                    onSubmit={(values) => {
                        if (values){
                            handleClaimAccount(values);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                    }) => (
                        <form className="" onSubmit={handleSubmit}>
                           
                            <div className="mb-2">
                            <textarea
                                disabled={false}
                                type={'text'}
                                name={'description'}
                                autoComplete={'off'}
                                id={'description'}
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                placeholder={'Describe your claim here'}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={5}
                                
                            // value={}
                            ></textarea>
                            <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                {touched['description'] && errors['description'] && errors['description']}
                            </div>
                            </div>
                            
                            <div className="footer-bottom-modal w-100 d-flex align-items-center justify-content-end mt-5">
                                    <button
                                    type="button"
                                    className="bg-tranparent border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg w-[119px]"
                                    onClick={()=>{openHideReportModal()}}
                                    >
                                    Cancel
                                    </button>
                                    <button
                                    type="submit"
                                    disabled={btnLoading}
                                    className="bg-themecolor text-white border py-2 px-8 mr-3 rounded-lg border-themecolor text-themecolor text-lg disabled:opacity-50 w-[119px] flex justify-center items-center"
                                    // onClick={()=>handleAcceptRejectCancel()}
                                    
                                    >
                                    {btnLoading ? (
                <div className="h-5 w-5">
                    <Loadingicon />
                </div>
            ) : 'Claim'
        }
                                    </button>  
                            </div>
                        </form>
                    )}
                </Formik>
             
            </Modal>
        </>
    );
}

export default CorporateDetail;
