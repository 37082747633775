import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal, yearSatra } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getTutoirialListing } from '../../../../api/tutorial';
import CompanyListItem from '../common/companylist';
import { getCompaniesByTypeWise, getSearchedData } from '../../../../api/corporate';
import { useSearch } from '../../../../Context/SearchContext';
function CorporateCompaniesListSearch() {

    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const location = useLocation();
    const paramDatas = new URLSearchParams(location.search);
    const { searchValue } = useSearch();
    const navigation = useNavigate();
    const params = useParams();
    useEffect(() => {
        const searchTerm = paramDatas?.get('searchTerm');
        if (searchTerm) {
            searchCompany(1, searchTerm);
            setSearchTerm(searchTerm);
        }
    }, [])

    useEffect(() => {
        const searchTerm = paramDatas?.get('searchTerm');
        if (searchTerm) {
            searchCompany(1, searchTerm);
            setSearchTerm(searchTerm);
        } else {
            setSearchTerm('');
            navigation('/corporates');
        }

    }, [searchValue])




    //load more blog by subcat data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        searchCompany(nxtPage, searchTerm, 'loadMore');
        setPageNo(nxtPage);
    }

    const searchCompany = async (pNo, keyword, loadMore = null) => {
        setIsSearch(false);
        !loadMore && setLoading(true);
        !loadMore && setPageNo(1);
        const resSearchCompany = await getSearchedData({ pageNo: pNo, keyword });
        setIsSearch(true);

        if (resSearchCompany?.success) {
            setTotalRecordFound(resSearchCompany?.data?.corporates?.total || 0)
            setIsNotFound(false)
            resSearchCompany?.data?.corporates?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resSearchCompany?.data?.corporates?.data?.length && pNo == 1) {
                setAllContent(resSearchCompany?.data?.corporates?.data);
            } else if (resSearchCompany?.data?.corporates?.data?.length && pNo !== 1) {
                setAllContent([...allContent, ...resSearchCompany?.data?.corporates?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
    }
    return (<>
        {/* <BreadCrumbs /> */}
        <div className='d-flex align-items-center justify-content-between'>
            <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        <NavLink to='/corporates' style={{ fontSize: 14, textDecoration: 'none' }}>Corporates</NavLink>
                    </li>
                    <li
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                    >
                        {slugToNormal(`Search Companies for '${searchTerm}'`)}
                    </li>
                </ol>
            </nav>
        </div>

        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">


                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allContent?.map((content, sIndex) => (
                                <CompanyListItem companyData={content} isSearch={isSearch ? true : false} filterType={'industry'} redirectionFn={() => navigation(`/corporates/company/${content?.slug}-${content?.id}`)} key={isSearch ? sIndex + 'search' : sIndex + 'main'} stripBottom={true} />
                            ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
        </div>

    </>)
}

export default CorporateCompaniesListSearch;