import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Loadingicon } from "../../../../AppIcons";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useAuth } from "../../../../Context/AuthContext";
import { Formik } from "formik";
import CustomSelect from "../../../common/customselect";
import { getForumSubject, getForumTopic, postAForumQuestion } from "../../../../api/forum";
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, slugToNormal, TINY_EDITOR_KEY } from "../../../../utils/utils";
import { useSearch } from "../../../../Context/SearchContext";
import { getBlogCategory, getBlogSubCategory, postBlog } from "../../../../api/blog";
import { Editor } from "@tinymce/tinymce-react";
import FAQItem from "../faq";
function EditBlog() {
    const moderateEditorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [draftBtnLoading, setDraftBtnLoading] = useState(false);
    const [catId, setCatId] = useState(null);
    const [sCatId, setSCatId] = useState();
    const [isCategoryLoading, setIsCategoryLoading] = useState(false);
    const [isSubCategoryLoading, setIsSubCategoryLoading] = useState(false);
    const { globalToken } = useAuth();
    const [categoryFormatted, setCategoryFormatted] = useState([]);
    const [subCategoryFormatted, setSubCategoryFormatted] = useState([]);
    const [defaultSCat, setDefaultSCat] = useState({});
    const [defaultCat, setDefaultCat] = useState({});
    const [sfValue, setSfValue] = useState(null);
    const [choosenBlog, setChoosenBlog] = useState(JSON.parse(localStorage.getItem('b__e') || '{}'));
    const [submitAction, setSubmitAction] = useState();
    const location = useLocation();
    const [faqContainers, setFaqContainers] = useState();



    const DisplayingErrorMessagesSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        cat_id: Yup.string().required("Category is required"),
        scat_id: Yup.string().required("Sub Category is required")
    });
    const DisplayingErrorMessagesSchemaSec = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        cat_id: Yup.string().required("Category is required")
    });

    const navigate = useNavigate();
    useEffect(() => {
        if(choosenBlog){
        setFaqContainers(choosenBlog?.faq?.length?JSON.parse(choosenBlog?.faq):[{ question: '', answer: '' }]);
        handleFetchAllBlogCategory(0);
        }
        return () => {              
              localStorage.removeItem('b__e'); 
            }
           
    }, [])

    const handlePlusClick = () => {
        // Add a new FAQ container and expand its size
        setFaqContainers([...faqContainers, { question: '', answer: '' }]);
    };


    const handleMinusClick = (index) => {
        // Remove the relevant FAQ container
        setFaqContainers(faqContainers.filter((_, i) => i !== index));
    };

    const handleFetchAllBlogCategory = async (pageNo) => {
        const resAllBlogCategory = await getBlogCategory({ pageNo });
        if (resAllBlogCategory?.success) {
            const blogCat = resAllBlogCategory?.data;
            if (blogCat?.length) {
                const cData = blogCat?.map(option => ({
                    value: option.id,
                    label: option.name
                })) || [];
                setCategoryFormatted(cData);
                if(choosenBlog?.cat_id && cData?.length){
                findChoosenCat(choosenBlog?.cat_id, cData);
                handleFetchAllBlogSubCategory(0, choosenBlog?.cat_id);
                }

            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
    }


    // fetch all subcategory by categry
    const handleFetchAllBlogSubCategory = async (pageNo, blogCatId) => {
        const resAllBlogSubCategory = await getBlogSubCategory({ pageNo, catId:blogCatId });
        if (resAllBlogSubCategory?.success) {
            const blogSCat = resAllBlogSubCategory?.data;
            if (blogSCat?.length) {
                const sCData = blogSCat?.map(option => ({
                    value: option.id,
                    label: option.name
                })) || [];
                setSubCategoryFormatted(sCData);
                if(choosenBlog?.cat_id && choosenBlog?.scat_id){
                    findChoosenSubCat(choosenBlog?.scat_id, sCData);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
    }

    const formData = [
        {
            id: 'title',
            title: 'Title',
            type: 'text',
            name: 'title',
            placeholder: 'Blog Title',
            required: true,
            editable: true
        },
        {
            id: 'cat_id',
            title: 'Category',
            type: 'text',
            name: 'cat_id',
            placeholder: 'Pick one from the list',
            required: true,
            editable: true,
            isCategory: true
        }, {
            id: 'scat_id',
            title: 'Sub Category',
            type: 'text',
            name: 'scat_id',
            placeholder: '',
            required: true,
            editable: true,
            isSCategory: true

        },
        {
            id: 'description',
            title: 'Description',
            type: 'text',
            name: 'description',
            placeholder: 'Put your blog description here',
            required: true,
            editable: true,
            width: true
        }
    ]


    const handleUpdateBlog = async (values, type) => {
        type=='post'?setSubmitBtnLoading(true):setDraftBtnLoading(true);        
        const resUpdateBlog = await postBlog({token:globalToken, id:choosenBlog?.id, title:values?.title, description:values?.description, cat_id:values?.cat_id, scat_id:values?.scat_id, type:type, image:values?.image, faq:faqContainers });
        if(resUpdateBlog?.success){
            toast.success(resUpdateBlog?.data?.message || 'Blog updated successfully.');
            navigate('/blogs/my-blogs');
        }else{
            toast.error(resUpdateBlog?.errors[0])
        }
        setSubmitBtnLoading(false);
        setDraftBtnLoading(false);
    }

    const handleCustomSelectOnChange = (value, type, label, setFieldValue) => {

        if (type == 'category') {
            setSfValue(setFieldValue);
            setSubCategoryFormatted([]);
            setDefaultSCat(null);
            setFieldValue('scat_id', null);

            if (value) {
                handleFetchAllBlogSubCategory(0, value)
                setCatId(value);
                setFieldValue('cat_id', value);
            } else {
                setCatId(null);
                setFieldValue('cat_id', null);
                // setCategoryFormatted([]);
            }
            setSCatId(null);

        }
        if (type == 'sCategory') {
            if (value) {
                findChoosenSubCat(value);
                setSCatId(value);
                setFieldValue('scat_id', value);
            } else {
                setSCatId(null);
                setDefaultSCat(null);
                setFieldValue('scat_id', null);
            }

        }

    }

    const findChoosenSubCat = (choosenSCat, sCData) => {
        const cSCat = sCData?.filter(sc => sc?.value == choosenSCat);
        setDefaultSCat(cSCat);
    }

    const findChoosenCat = (choosenCat, cData) => {        
        const cCat = cData?.filter(sc => sc?.value == choosenCat);
     
        setDefaultCat(cCat);
    }


    return (
        <>
            {/* <BreadCrumbs /> */}
            <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                        <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea mb-0" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/blogs' style={{ fontSize: 14, textDecoration: 'none' }}>Blogs</NavLink>
                            </li>
                            <li className={`breadcrumb-item capitalize rest-bread`}>
                                <NavLink to='/blogs/my-blogs' style={{ fontSize: 14, textDecoration: 'none' }}>My Blogs</NavLink>
                            </li>    
                            {
                                choosenBlog? <li className={`breadcrumb-item capitalize active clamping ellipsis`}>
                                {choosenBlog?.title}
                            </li>:null
                            }                        
                           
                            <li className={`breadcrumb-item capitalize active clamping ellipsis`}>
                                Edit
                            </li>
                        </ol>
                    </nav>
            {loading ? (
                <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                    <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                        <div className="h-10 w-10">
                            <Loadingicon />
                        </div>
                    </div>
                </div>) : (
                <div className="py-0 px-0 w-100 card-body gap-4s">

                    <div className="p-4 w-100 my-2">
                      
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                description: choosenBlog?.description,
                                cat_id: choosenBlog?.cat_id,
                                scat_id: choosenBlog?.scat_id,
                                title:choosenBlog?.title
                            }}
                            validationSchema={sCatId ? DisplayingErrorMessagesSchemaSec : DisplayingErrorMessagesSchema}
                            onSubmit={(values) => {
                                if (values){
                                if (submitAction === 'post') {
                                    handleUpdateBlog(values, 'post') // Call post function
                                } else if (submitAction === 'draft') {
                                    handleUpdateBlog(values,'draft') // Call draft function
                                }
                            }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                            }) => (
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="row">
                                        {
                                            formData?.map(fieldMeta => (
                                                <div className={`position-relative  ${fieldMeta.width ? 'col-md-12' : 'col-md-12'}`}>
                                                    <label
                                                        htmlFor={fieldMeta?.id}
                                                        className={`block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500`}
                                                    >
                                                        {fieldMeta?.title}

                                                        {
                                                            fieldMeta?.required ? <span className="text-red-400 text-base">*</span> : null
                                                        }
                                                    </label>
                                                    <input type="password" name="fake-password" autoComplete="new-password" style={{ display: 'none' }} />
                                                    {
                                                        fieldMeta.width ?
                                                            <>
                                                                <Editor
                                                                    apiKey={TINY_EDITOR_KEY}
                                                                    onInit={(evt, editor) => moderateEditorRef.current = editor}
                                                                    initialValue={choosenBlog?.description}
                                                                    init={{
                                                                        height: '200',
                                                                        auto_focus: true,
                                                                        menubar: false,
                                                                        plugins: 'hr lists table textcolor code link image',
                                                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic forecolor link image media | alignleft aligncenter alignright | hr bullist numlist table | subscript superscript | removeformat code',

                                                                        // allow custom url in link? nah just disabled useless dropdown..
                                                                        file_picker_types: 'image',
                                                                        file_picker_callback: function (cb, value, meta) {
                                                                            var input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');
                                                                            input.onchange = () => {
                                                                                const file = input.files[0];
                                                                                const reader = new FileReader();

                                                                                reader.onload = () => {
                                                                                    const base64 = reader.result.split(',')[1];
                                                                                    cb('data:image/jpeg;base64,' + base64);
                                                                                };

                                                                                reader.readAsDataURL(file);
                                                                            };

                                                                            input.click();
                                                                        },
                                                                        anchor_top: false,
                                                                        anchor_bottom: false,
                                                                        branding: false,
                                                                        loading: true
                                                                    }}
                                                                    onEditorChange={(content, editor) => {
                                                                        setFieldValue(fieldMeta?.id, content);

                                                                    }}
                                                                    />
                                                                {/* <textarea type={fieldMeta?.type}

                                                                    name={fieldMeta?.name}
                                                                    autoComplete={'off'}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    id={fieldMeta?.name} rows={6} placeholder={fieldMeta?.placeholder} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 w-100 focus:border-primary-600 block w-full p-2.5">{choosenBlog?.description}</textarea> */}
                                                                <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                    {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                </div>
                                                            </>
                                                            : fieldMeta.isCategory ?
                                                                <div className="w-100" style={{zIndex:101, position:'relative'}}>

                                                                    <div className="d-block w-100">
                                                                        {
                                                                            <CustomSelect options={categoryFormatted} 
                                                                            defaultOption={defaultCat}
                                                                            isMultiple={false} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'category'} isSelectLoading={isCategoryLoading} placeholder={'Category'} />
                                                                        }

                                                                    </div>

                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                    </div>
                                                                </div> : fieldMeta.isSCategory ?
                                                                    <div className="w-100" style={{zIndex:100, position:'relative'}}>


                                                                        <div className="d-block w-100">
                                                                            <CustomSelect
                                                                                options={subCategoryFormatted}
                                                                                defaultOption={defaultSCat}
                                                                                isMultiple={false} isClearable={true} handleCustomSelectOnChange={handleCustomSelectOnChange}
                                                                                setFieldValue={setFieldValue}
                                                                                type={'sCategory'} isSelectLoading={isSubCategoryLoading} placeholder={'Sub Category'} />
                                                                        </div>

                                                                        <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                            {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                        </div>
                                                                    </div> : <>
                                                                    <input
                                                                                        disabled={!fieldMeta?.editable}
                                                                                        type={fieldMeta?.type}
                                                                                        name={fieldMeta?.id}
                                                                                        autoComplete={'off'}
                                                                                        id={fieldMeta?.name}
                                                                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                                                                                        placeholder={fieldMeta?.title}
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        value={values[fieldMeta?.id] || ""}
                                                                                    />
                                                                                    <div className="text-red-500 mt-1 text-sm text-right min-h-[20px]">
                                                                                        {touched[fieldMeta?.name] && errors[fieldMeta?.name] && errors[fieldMeta?.name]}
                                                                                    </div>
                                                                    </>}

                                                </div>
                                            ))
                                        }
                                        {
                                            choosenBlog?.image? <div className="col-md-1 mb-3">
                                            <div className="image-container rounded">
                                                <img src={CDN_BASE_URL + '' + choosenBlog?.image} alt="blog image" className="rounded" />

                                            </div>
                                        </div>:''
                                        }
                                        
                                        <div className="col-md-11 mb-3">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-500" >Set Blog Image (Supported Formats JPG,PNG,BMP,WEBP)</label>
                                        <input type="file" onChange={(event) => {
                                            setFieldValue('image', event.currentTarget.files[0]);
                                        }}
                                            onBlur={handleBlur}
                                            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                            name="image" />
                                    </div>
                                    <label htmlFor={'faq'}
                                                    className={`block mb-2 text-sm font-medium position-relative text-gray-900 dark:text-gray-500`}
                                                >
                                                    FAQ
                                                    <button className="btn-plus" type="button" onClick={()=> handlePlusClick()}><i className="fa fa-plus"></i></button>
                                                </label>
                                                <div className="col-md-12">                                            {
                                                        faqContainers?.length?faqContainers?.map((fq, index)=>(
                                                            <FAQItem key={fq.id} TINY_EDITOR_KEY={TINY_EDITOR_KEY} moderateEditorRef={moderateEditorRef} faq={faqContainers} fq={fq} setFaqContainers={setFaqContainers} faqIndex={index} handleMinusClick={handleMinusClick} setFieldValue={setFieldValue}/>
                                                        )):null
                                                    }                                                    
                                                </div>
                                    </div>
                                  
                                    <div className="col-md-4 d-flex gap-2 mt-3">
                                        <button
                                            type="submit"
                                            disabled={submitBtnLoading}
                                            onClick={() => setSubmitAction('post')}
                                            className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                        >
                                            {submitBtnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={draftBtnLoading}
                                            onClick={() => setSubmitAction('draft')}
                                            className="mt-2 w-full text-white bg-themecolor hover:bg-opacity-75 disabled:bg-opacity-50 focus:ring-1 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center"
                                        >
                                            {draftBtnLoading ? (
                                                <div className="h-5 w-5">
                                                    <Loadingicon />
                                                </div>
                                            ) : (
                                                "Save As Draft"
                                            )}
                                        </button>
                                    </div>
                                </form>
                            )}

                        </Formik>
                    </div>
                </div>


            )
            }

        </>
    );
}
export default EditBlog;
