import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, slugToNormal, yearSatra } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteTutorial, getMyTutoirialsListing } from '../../../../api/tutorial';
import TutorialListItem from '../common/tutorialslist';
import { useAuth } from '../../../../Context/AuthContext';
import Modal from "react-bootstrap/Modal";
function MyTutorialsList() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [tabLoading, setTabLoading] = useState(true);
    const [tutorialSlug, setTutorialSlug] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [choosenTutorial, setChoosenTutorial] = useState();
    const [filterType, setFilterType] = useState(0);
    const navigation = useNavigate();
   
    const params = useParams();

    useEffect(() => {
            handleTutorialByTag(0, 1);
    }, [])

    // fetch all tutorials by logged user
    const handleTutorialByTag = async (fType, pageNo) => {
        const resAllMyTutorials = await getMyTutoirialsListing({ pageNo: pageNo, token: globalToken, status:fType });
        if (resAllMyTutorials?.success) {
            setIsNotFound(false)
            resAllMyTutorials?.data?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            console.log(resAllMyTutorials);
            if (resAllMyTutorials?.data?.data?.length && pageNo == 1) {
                setAllContent(resAllMyTutorials?.data?.data);
            } else if (resAllMyTutorials?.data?.data?.length && pageNo !== 1) {
                setAllContent([...allContent, ...resAllMyTutorials?.data?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
        
        setShowDeleteModal(false);  
        setBtnLoading(false);  
        setTabLoading(false)
    }


    //load more tutorial by subcat data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleTutorialByTag(filterType, nxtPage);
        setPageNo(nxtPage);
    }

    const handleDeleteTutorial = async () =>{
        setBtnLoading(true);
        const resDeleteTutorial = await deleteTutorial({token: globalToken, tutorialId:choosenTutorial?.id})
        if(resDeleteTutorial.success){
            toast.success(resDeleteTutorial?.message)
            handleTutorialByTag(filterType, pageNo);        
        }else{
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        
    }

    const handleDeleteModal = (cTutorial) => {
        setChoosenTutorial(cTutorial)
        setShowDeleteModal(!showDeleteModal);
        };

        const handleFilterChange = (v) => {
            setTabLoading(true)
            setAllContent([])
            const fValue = v;
            setFilterType(fValue);
            handleTutorialByTag(fValue, pageNo);
        }

    const tabItems = [{
        name: 'Pending',
        value: 0
    }, {
        name: 'Drafted',
        value: 3
    }, {
        name: 'Approved',
        value: 1
    }
    ]
return (<>
        <BreadCrumbs />
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong> My Tutorials</strong>`} subTitle={`Dive into tutorials designed and shared from my experience.`} />
                        <div className='btn-group question-box question-box-card'>                       
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/tutorials/post-a-tutorial')}
                            >
                            Post A Tutorial
                            </button>                    
                        </div>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) 
                        // : isNotFound ? (
                        //     <div className="flex-column not-found d-flex text-center">
                        //         <img src={notFound} className="notFoundImg" />
                        //         <span style={{ opacity: 0.7 }}>No data found</span>
                        //     </div>) 
                            : <div className='row'>
                                <ul className="nav nav-pills mb-3 tab-custom" id="pills-tab" role="tablist">
                            {
                                tabItems?.map((tab, itab) => (
                                    <li className="nav-item" key={itab + 'tab'} role="presentation">
                                        <button className={`nav-link ${itab == 0 ? 'active' : ''}`} id={`pills-${tab?.value}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-${tab?.value}`} type="button" role="tab" aria-controls={`pills-${tab?.value}`} aria-selected="true" onClick={()=>handleFilterChange(tab?.value)}>
                                            {tab?.name}
                                        </button>
                                    </li>
                                ))
                            }

                        </ul>
                        <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-0" role="tabpanel" aria-labelledby="pills-ranking-tab">
                                {
                                    (!tabLoading && !allContent?.length)?<div className="flex-column not-found d-flex text-center" >
                                    <img src={notFound} className="notFoundImg" />
                                    <span style={{ opacity: 0.7 }}>No data found</span>
                                </div>:tabLoading? (
                                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[200px]">
                                                <div className="h-10 w-10">
                                                    <Loadingicon />
                                                </div>
                                            </div>
                                        </div>) :allContent?.map((content, sIndex) => (
                                <TutorialListItem stripBottom={true} handleDeleteModal={handleDeleteModal} isEdit={true} tutorialData={content} redirectionFn={() => navigation(`/tutorials/detail/${content?.slug}-${content?.id}`)} key={sIndex}/>
                                ))
                                }
                            </div>
                            <div class="tab-pane fade" id="pills-1" role="tabpanel" aria-labelledby="pills-ranking-tab">
                                {
                                   (!tabLoading && !allContent?.length)?<div className="flex-column not-found d-flex text-center" >
                                   <img src={notFound} className="notFoundImg" />
                                   <span style={{ opacity: 0.7 }}>No data found</span>
                               </div>:tabLoading? (
                                    <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                                        <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[200px]">
                                            <div className="h-10 w-10">
                                                <Loadingicon />
                                            </div>
                                        </div>
                                    </div>):allContent?.map((content, sIndex) => (
                                <TutorialListItem stripBottom={true} handleDeleteModal={handleDeleteModal} isEdit={true} tutorialData={content} redirectionFn={() => navigation(`/tutorials/detail/${content?.slug}-${content?.id}`)} key={sIndex}/>
                                ))
                                }
                            </div>
                            <div class="tab-pane fade" id="pills-3" role="tabpanel" aria-labelledby="pills-ranking-tab">
                                {
                                    (!tabLoading && !allContent?.length)?<div className="flex-column not-found d-flex text-center" >
                                    <img src={notFound} className="notFoundImg" />
                                    <span style={{ opacity: 0.7 }}>No data found</span>
                                </div>:tabLoading? (
                                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[200px]">
                                                <div className="h-10 w-10">
                                                    <Loadingicon />
                                                </div>
                                            </div>
                                        </div>):allContent?.map((content, sIndex) => (
                                <TutorialListItem stripBottom={true} handleDeleteModal={handleDeleteModal} isEdit={true} tutorialData={content} redirectionFn={() => navigation(`/tutorials/detail/${content?.slug}-${content?.id}`)} key={sIndex}/>
                                ))
                                }
                            </div>
                        </div>
                        {/* {
                            allContent?.map((content, sIndex) => (
                                <TutorialListItem stripBottom={true} handleDeleteModal={handleDeleteModal} isEdit={true} tutorialData={content} redirectionFn={() => navigation(`/tutorials/detail/${content?.slug}-${content?.id}`)} key={sIndex}/>
                                ))
                        } */}

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                  
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

         {/* delete alert START  */}
      <Modal
        show={showDeleteModal}
        onHide={handleDeleteModal}
        
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
        <strong>Hey!</strong> Do you realy want to delete this tutorial?
        <div className="card mt-4">
            <div className="card-body bg-gray running">
                <h6><strong>Tutorial: <span className="color-gray">{choosenTutorial?.title}</span></strong></h6>
            </div>
        </div>
         
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <button
            type="button"
            disabled={btnLoading}
            className="btn btn-warning custom-theme-button d-flex align-items-center"
            onClick={() => handleDeleteTutorial()}
          >
            {btnLoading ? (
                <div className="h-5 w-5">
                    <Loadingicon />
                </div>
            ) : (
                "Yes"
            )}
            
          </button>
          <button
            type="button"
            className="btn btn-light d-flex align-items-center ms-2"
            onClick={() => handleDeleteModal()}
          >
            No{" "}
          </button>
        </Modal.Footer>
      </Modal>
      {/* delete alert popup END  */}
    </>)
}

export default MyTutorialsList;