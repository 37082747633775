import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, slugToNormal, yearSatra } from '../../../../utils/utils';
import { getAllStreams } from '../../../../api/careernews';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getBlogCategory, getBlogListing, getBlogSubCategory } from '../../../../api/blog';
import BlogCatSubCatList from '../common/catsubcat';
import BlogListItem from '../common/tutorialslist';
import { getSearchedData, getTutoirialListing } from '../../../../api/tutorial';
import TutorialListItem from '../common/tutorialslist';
import { useSearch } from '../../../../Context/SearchContext';
function TutorialsList() {

    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [tutorialSlug, setTutorialSlug] = useState(); 
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const navigation = useNavigate();
    const location = useLocation();
    const paramDatas = new URLSearchParams(location.search);
    const { searchValue } = useSearch();

    const params = useParams();

    useEffect(() => {
        const searchTerm = paramDatas?.get('searchTerm');
        if(searchTerm){
            searchTutorial(1, searchTerm);
            setSearchTerm(searchTerm);
        } else if (params?.tutorialTagSlug) {
            // const tId = (params?.tutorialTagSlug).split('-').pop();
            setTutorialSlug(params?.tutorialTagSlug)
            handleBlogsByTag(1, params?.tutorialTagSlug);
        }
    }, [])
    useEffect(() => {
              
        const searchTerm = paramDatas?.get('searchTerm');
        if(searchTerm){
            searchTutorial(1, searchTerm);
            setSearchTerm(searchTerm);
        }else{
            setSearchTerm('');
            setIsSearch(false)
            if(tutorialSlug){
            handleBlogsByTag(1, tutorialSlug);
            }
        }
            
    }, [searchValue])

    // fetch all blogs by subcategory
    const handleBlogsByTag = async (pageNo, tSlug) => {
        const resAllTutorialByTag = await getTutoirialListing({ pageNo: pageNo, tagSlug: tSlug });
        if (resAllTutorialByTag?.success) {
            setIsNotFound(false)
            resAllTutorialByTag?.data?.tutorials?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            // console.log(resAllTutorialByTag);
            if (resAllTutorialByTag?.data?.tutorials?.data?.length && pageNo == 1) {
                setAllContent(resAllTutorialByTag?.data?.tutorials?.data);
            } else if (resAllTutorialByTag?.data?.tutorials?.data?.length && pageNo !== 1) {
                setAllContent([...allContent, ...resAllTutorialByTag?.data?.tutorials?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }


    //load more blog by subcat data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        
        if (searchTerm?.length) {
            searchTutorial(nxtPage, searchTerm, 'loadMore');
        }else{
            handleBlogsByTag(nxtPage, tutorialSlug);
        }
        setPageNo(nxtPage);
    }

    const handleSearchTutorial = (e) => {
        const searchTxt = e.target.value;
        setPageNo(1);
        setSearchTerm(searchTxt);
       
        if (searchTxt == '') {   
            setIsSearch(false);           
            setLoading(true);          
            handleBlogsByTag(1, tutorialSlug);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            setIsSearch(false);
            searchTutorial(1, searchTxt);
        }
    }

    const searchTutorial = async (pNo, keyword, loadMore=null) => {
        !loadMore && setLoading(true);
        const resSearchTutorials = await getSearchedData({ pageNo: pNo, keyword });
        setIsSearch(true);

        if (resSearchTutorials?.success) {
            setTotalRecordFound(resSearchTutorials?.data?.total || 0)
            setIsNotFound(false)
            resSearchTutorials?.data?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resSearchTutorials?.data?.data?.length && pNo == 1) {
                setAllContent(resSearchTutorials?.data?.data);
            } else if (resSearchTutorials?.data?.data?.length && pNo !== 1) {
                setAllContent([...allContent, ...resSearchTutorials?.data?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
    }

    return (<>
         <div className='d-flex align-items-center justify-content-between'>
            <BreadCrumbs />
            <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handleSearchTutorial} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder={`Search Tutorial`}/>
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchTutorial(1, searchTerm)}></i>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    {
                        !isSearch?<><div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong> Top 10 Tutorials To Master ${slugToNormal(params?.tutorialTagSlug)} Skills in ${yearSatra()}</strong>`} subTitle={`Our Top 10 ${slugToNormal(params?.tutorialTagSlug)} blog listing is constantly updated in ${yearSatra()} with the latest content from experienced authors and working professionals, ensuring you stay up-to-date with the most relevant and cutting-edge insights. With a finger on the pulse of ${slugToNormal(params?.tutorialTagSlug)} trends, our blogs explore emerging technologies, best practices, case studies, and thought-provoking analyses that shape the Marketing domain.`} />
                        <div className='btn-group question-box question-box-card mw-220 d-flex justify-content-end'>
                            <button
                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/tutorials/my-tutorials')}
                            >
                                My Tutorials
                            </button>
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/tutorials/post-a-tutorial')}
                            >
                            Post A Tutorial
                            </button>                    
                        </div>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />
</>:null
                    }
                    
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allContent?.map((content, sIndex) => (
                                <TutorialListItem  tutorialData={content} redirectionFn={() => navigation(`/tutorials/detail/${content?.slug}-${content?.id}`)} key={sIndex}/>
                                ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                  
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default TutorialsList;