import React, { useEffect, useRef, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { ERROR_SOMETHING_WENT_WRONG, yearSatra } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { useNavigate } from 'react-router-dom';
import TutorialCategoryList from '../common/catsubcat';
import { getCorporateIndustries, getCorporateWiseFilter } from '../../../../api/corporate';
import CorporateIndustryList from '../common/catsubcat';
import { getEducatorWiseFilter, getSearchedData } from '../../../../api/educator';
import EducatorCategoryList from '../common/catsubcat';
function EducatorCategory() {

    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [allFilteredContent, setAllFilteredContent] = useState([]);
    const navigation = useNavigate();
    const [filterType, setFilterType] = useState('streams');
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const searchBox = useRef(null);

    useEffect(() => {
        handleFetchAllEducatorCategory(1, filterType);
    }, [])

    // fetch all streams type
    const handleFetchAllEducatorCategory = async (pageNo, fType) => {
        const resAllEducatorWiseFilter = await getEducatorWiseFilter({ pageNo: pageNo, fType });
        const filType = (fType == 'admissions')?'courses':fType;
        if (resAllEducatorWiseFilter?.success) {
            setIsNotFound(false);

            const hasNextPage = resAllEducatorWiseFilter[filType]?.next_page_url

            hasNextPage ? setShowLoadMore(true) : setShowLoadMore(false);

            const hasData = (resAllEducatorWiseFilter[filType]?.data?.length);

            if (hasData && pageNo === 1) {
                setAllContent(resAllEducatorWiseFilter[filType]?.data);
                setAllFilteredContent(resAllEducatorWiseFilter[filType]?.data);
            } else if (hasData && pageNo !== 1) {
                setAllContent([...allContent, ...(resAllEducatorWiseFilter[filType]?.data
                )
                ]);
                setAllFilteredContent([...allContent, ...(resAllEducatorWiseFilter[filType]?.data
                )
                ]);
            } else {
                if (pageNo === 1) {
                    setAllContent([]);
                    setAllFilteredContent([])
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG);
        }


        setLoading(false);
        setLoadingLoadMore(false);
    }


    //load more category data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllEducatorCategory(nxtPage, filterType);
        setPageNo(nxtPage);
        searchBox.current.value = '';
        setIsSearch(false)
    }

    const handleSearchCategory = (e) => {
        const searchTxt = e.target.value;
        setSearchTerm(searchTxt);
        if (searchTxt == '') {
            setIsSearch(false); 
            setFilterType(filterType);          
            handleFetchAllEducatorCategory(1, filterType);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            setIsSearch(true);
            searchCategory(1, searchTxt);
        }
    }

    const searchCategory = async (pNo, keyword, loadMore=null) => {
        !loadMore && setLoading(true);
        const res = await getSearchedData({ pageNo: pNo, keyword });
        console.log(res)
        setIsSearch(true);

        if (res?.success) {
            let filteredResults;
            switch (filterType) {
            case 'streams':
                filteredResults = res?.data?.streams;
                break;
            case 'courses':
                filteredResults = res?.data?.courses;
                break;
            case 'specializations':
                filteredResults = res?.data?.specializations;
                break;
            case 'admissions':
                filteredResults = res?.data?.admissions;
                break;
            case 'states':
                filteredResults = res?.data?.states;
                break;
            case 'cities':
                filteredResults = res?.data?.cities;
                break;
            case 'localities':
                filteredResults = res?.data?.localities;
                break;
            case 'types':
                filteredResults = res?.data?.types;
                break;
            default:
                filteredResults = [];
                break;
            }
            setTotalRecordFound(filteredResults?.length || 0)
            setIsNotFound(false)
            filteredResults?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (filteredResults?.length && pNo == 1) {
                setAllFilteredContent(filteredResults);
            } else if (filteredResults?.length && pNo !== 1) {
                setAllFilteredContent([...allContent, ...filteredResults]);
            } else {
                if (pageNo == 1) {
                    setAllFilteredContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
    }


    // filter type data
    const filterTypeData = [
        {
            name: 'Streams',
            value: 'streams',
        }, {
            name: 'Courses',
            value: 'courses'
        }, {
            name: 'Specializations',
            value: 'specializations'
        }, {
            name: 'Admissions',
            value: 'admissions'
        }, 
        // {
        //     name: 'Exams',
        //     value: 'exams'
        // },
         {
            name: 'State',
            value: 'states'
        }, {
            name: 'City',
            value: 'cities'
        }, {
            name: 'Locality',
            value: 'localities'
        }, {
            name: 'Educator Type',
            value: 'types'
        }
    ]


    // handle filter change 
    const handleFilterChange = (e) => {
        setPageNo(1);
        const fValue = e.target.value;
        setFilterType(fValue);
        handleFetchAllEducatorCategory(1, fValue);
    }

    return (<>
        
        <div className='d-flex align-items-center justify-content-between'>
            <BreadCrumbs />
            <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handleSearchCategory} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder={`Search ${filterType}`} />
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchCategory(1, searchTerm)}></i>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    {
                        !isSearch?<><div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={ `<strong>Top 10 Career ${filterType} in India  ${yearSatra()}: Discover Fees, Courses, Admission, Placements}</strong>`} subTitle={`Top 10 Career ${filterType} in India including Animation, Architecture & Planning, Arts ( Fine / Visual / Performing ), Aviation, Banking, Finance & Insurance, Beauty & Fitness. Know all about courses, careers, subjects, scope, degree & entrance exams across career streams in India. This list will help you in making decisions regarding the right Career Stream selection for your career.`} />
                        <div className='filter-container ms-4' style={{ minWidth: 200 }}>
                            <select className='form-control mw-100' onChange={handleFilterChange} value={filterType}>
                                {
                                    filterTypeData?.map((fData, i) => <option key={i + 'ftype'} value={fData?.value}>{fData?.name}</option>)
                                }

                            </select>
                        </div>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></>:null
                    }
                    
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allFilteredContent?.map((content, sIndex) => (
                                <EducatorCategoryList content={content} boxSize='col-md-3 mb-3' key={sIndex} type="content" redirectionFn={() => navigation(`/educators/${filterType}/${content?.slug}`)} />
                            ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
        </div>

    </>)
}

export default EducatorCategory;