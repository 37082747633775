import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../../../Context/AuthContext";
import {
  convertDateFormat,
  nFormatter,
  slugToNormal,
  modulesUrls,
  ERROR_SOMETHING_WENT_WRONG,
  placeholderImages,
  CDN_BASE_URL,
  formatTime,
  stripHtmlTags,
} from "../../../../utils/utils";
import {
  forumCommentAndReply,
  // tutorialCommentAndReply,
  likeUnlikeDislike,
  likeUnlikeDislikeAnswer,
  // getTutorialChaptersList,
  // getTutorialDetailByChapter
} from "../../../../api/forum";
import Share from "../../../common/share";
import Modal from "../../../common/Modal";
import styles from "../../../../assets/css/styles/Home.module.css";
import notFound from "../../../../assets/images/not_found_new.png";
import { Loadingicon } from "../../../../AppIcons";
import { getForumDetails } from "../../../../api/forum";
import ForumAnswers from "../../../common/comments/forumAnswers";

function ForumDetail() {
  const { globalToken } = useAuth();
  const [pageNo, setPageNo] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [upvoteBtnLoader, setUpvoteBtnLoader] = useState(false);
  const [downvoteBtnLoader, setDownvoteBtnLoader] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);
  const [forumDetailData, setForumDetailData] = useState([]);
  const [forumId, setForumId] = useState();
  const [forumSlug, setForumSlug] = useState();
  const [forumCatSlug, setForumCatSlug] = useState();
  const [forumSubCatSlug, setForumSubCatSlug] = useState();
  const [isShareActive, setIsShareActive] = useState(false);
  const navigation = useNavigate();
  const params = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [questionLikeCount, setQuestionLikeCount] = useState(false);
  const [questionDislikeCount, setQuestionDislikeCount] = useState(false);
  const [answerCount, setAnswerCount] = useState();
  const [isLocationChange, setIsLocationChange] = useState(false);
  const [nextPrevLoader, setNextPrevLoader] = useState(false);
  const [nextPrevForumData, setNextPrevForumData] = useState([]);

  // Fetches forum details when component mounts or params change
  useEffect(() => {
    if (params?.forumSlug && params?.forumCatSlug && params?.forumSubCatSlug) {
      const fId = (params?.forumSlug).split("-").pop();
      setForumSlug(params?.forumSlug);
      setForumId(fId);
      setForumCatSlug(params?.forumCatSlug);
      setForumSubCatSlug(params?.forumSubCatSlug);
      handleFetchForumDetail(fId);
    }
  }, [isLocationChange]);

  // Handles fetching forum details by forum ID
  const handleFetchForumDetail = async (fId) => {
    const resForumDetail = await getForumDetails({
      token: globalToken,
      forumId: fId,
    });
    // console.log("forum detail", resForumDetail);
    setQuestionLikeCount(resForumDetail?.data?.questionLikes);
    setQuestionDislikeCount(resForumDetail?.data?.questionDisLikes);
    setIsLiked(resForumDetail?.data?.UserquestionLikes);
    setIsDisliked(resForumDetail?.data?.UserquestionDisLikes);
    setAnswerCount(resForumDetail?.data?.answerCount);
    setLoading(false);
    setLoadingLoadMore(false);
    if (resForumDetail?.success) {
      setIsNotFound(false);
      if (resForumDetail?.data?.question) {
        setForumDetailData(resForumDetail?.data?.question);
        setNextPrevForumData(resForumDetail?.data);
        console.log(resForumDetail?.data);
      } else {
        setForumDetailData({});
        setNextPrevForumData({});
        setIsNotFound(true);
      }
    } else {
      toast.error(ERROR_SOMETHING_WENT_WRONG);
    }
    setUpvoteBtnLoader(false);
    setDownvoteBtnLoader(false);
  };

  // Handles adding/removing lyrics from favorites
  // const FavouriteButton = ({ is_fav_camp }) => {
  //     const [favBtnLoading, setFavBtnLoading] = useState(false);

  //     const handleStartTask = async () => {
  //         setFavBtnLoading(true);
  //         const token = globalToken;
  //         const res = await bookMarkLyrics({ token: token, lyricId: forumDetailData.id, favourite: is_fav_camp ? 0 : 1 });
  //         if (res?.success) {
  //             handleFetchForumDetail(forumDetailData.id);
  //         }
  //         setFavBtnLoading(false);
  //     }

  //     return (
  //         <button
  //             type="button"
  //             disabled={favBtnLoading}
  //             onClick={handleStartTask}
  //             className="btn bg-white p-0 d-flex btn-like-circular"
  //             style={{ border: 'none', boxShadow: 'none' }}
  //         >
  //             {favBtnLoading ? (
  //                 <div className="h-5 w-5">
  //                     <Loadingicon />
  //                 </div>
  //             ) : (
  //                 <>
  //                     {is_fav_camp ? <i className="fa fa-heart heart active"></i> : <i className="fa heart fa-heart"></i>}
  //                 </>
  //             )}
  //         </button>
  //     );
  // }

  // Handles sharing the tutorial
  const InlineShare = ({ forumData, isShareActive, setIsShareActive }) => {
    const handleShareClick = () => {
      setIsShareActive(!isShareActive);
    };
    return (
      <small className="position-relative" title="Share">
        {isShareActive ? (
          <Share
            shareContent={{
              url: `${modulesUrls?.tuteehubForum}/forum/${forumCatSlug}/${forumSubCatSlug}/${forumSlug}`,
              quote: `${forumData?.question}`,
              hashing: `#tuteehub`,
              title: `${forumData?.question}`,
            }}
            isCustomPos={true}
          />
        ) : null}
        <span
          className={`${styles.icons} ${styles.shareIco}`}
          style={{ display: "inline-block" }}
          role="button"
          onClick={handleShareClick}
        ></span>
      </small>
    );
  };

  // Handles upvoting or downvoting the forum
  const handleLikeUnlikeDislike = async (voteType, type) => {
    if (type === "dLike") setDownvoteBtnLoader(true);
    else setUpvoteBtnLoader(true);
    const resLikeUnlike = await likeUnlikeDislike({
      token: globalToken,
      forumId: forumDetailData.id,
      voteType,
    });
    if (resLikeUnlike?.success) {
      handleFetchForumDetail(forumDetailData.id);
      toast.success(resLikeUnlike?.data?.data?.message);
    }
  };

  // Handles upvoting or downvoting the answer
  const handleLikeUnlikeDislikeAnswer = async (
    voteType,
    type,
    aId,
    setDownvoteAnsLoader,
    setUpvoteAnsLoader
  ) => {
    if (type === "dLike") setDownvoteAnsLoader(true);
    else setUpvoteAnsLoader(true);
    const resLikeUnlikeAns = await likeUnlikeDislikeAnswer({
      token: globalToken,
      forumId: forumDetailData.id,
      voteType,
      answerId: aId,
    });
    // console.log(resLikeUnlikeAns)
    if (resLikeUnlikeAns?.success) {
      handleFetchForumDetail(forumDetailData.id);
      toast.success(resLikeUnlikeAns?.data?.data?.message);
    }
    setUpvoteAnsLoader(false);
    setDownvoteAnsLoader(false);
  };

  // Handles submitting comments and replies
  const handleSubmitComment = async (
    commentId,
    setComment,
    comment,
    isReply,
    commentLoader,
    setIsReplyShow
  ) => {
    commentLoader(true);
    try {
      const resCommentCommit = await forumCommentAndReply({
        token: globalToken,
        forumId: forumDetailData?.id,
        answerId: isReply ? commentId : 0,
        comment,
      });
      if (resCommentCommit?.success) {
        setIsReplyShow(false);
        toast.success(resCommentCommit?.message);
        handleFetchForumDetail(forumDetailData?.id);
      }
      setComment("");
    } catch (error) {
      toast.error("Error sending comment");
    } finally {
      commentLoader(false);
    }
  };

  // Modifies anchor tags to open in a new tab
  const modifyAnchorTags = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const anchorTags = doc.querySelectorAll("a");
    anchorTags.forEach((tag) => {
      tag.setAttribute("target", "_blank");
    });
    return doc.documentElement.innerHTML;
  };

  return (
    <>
      {loading ? (
        <div className="w-full flex items-center justify-center py-4 text-themecolor min-h-[400px] group-card card-body">
          <div className="h-10 w-10 theme-color">
            <Loadingicon />
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between mb-3">
            <nav
              aria-label="breadcrumb"
              className="pt-0 px-0 pb-0 breadcrumb-card"
            >
              <ol
                className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea mb-0"
                style={{ display: "flex", flexWrap: "nowrap" }}
              >
                <li className={`breadcrumb-item capitalize rest-bread`}>
                  <NavLink
                    to="/"
                    style={{ fontSize: 14, textDecoration: "none" }}
                  >
                    Home
                  </NavLink>
                </li>
                <li className={`breadcrumb-item capitalize rest-bread`}>
                  <NavLink
                    to="/forum"
                    style={{ fontSize: 14, textDecoration: "none" }}
                  >
                    Forum
                  </NavLink>
                </li>
                <li className={`breadcrumb-item capitalize rest-bread`}>
                  <NavLink
                    to={`/forum/${forumSubCatSlug}`}
                    style={{ fontSize: 14, textDecoration: "none" }}
                  >
                    {slugToNormal(forumCatSlug)}
                  </NavLink>
                </li>
                <li className={`breadcrumb-item capitalize rest-bread`}>
                  <NavLink
                    to={`/forum/${forumCatSlug}/${forumSubCatSlug}`}
                    style={{ fontSize: 14, textDecoration: "none" }}
                  >
                    {slugToNormal(forumSubCatSlug)}
                  </NavLink>
                </li>
                <li
                  className={`breadcrumb-item capitalize active clamping ellipsis`}
                >
                  {slugToNormal(params.forumSlug)}
                </li>
              </ol>
            </nav>
            <div className="btn-group question-box question-box-card">
              <button
                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white"
                style={{ backgroundColor: "#ff7200" }}
                onClick={() => navigation("/forum/my-forum")}
              >
                My Forum
              </button>
              <button
                className="btn-soft cursor-pointer hover:bg-themecolor text-white"
                style={{ backgroundColor: "#ff7200" }}
                onClick={() => navigation("/forum/post-a-question")}
              >
                Post A Question
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4">
                <div className="px-0">
                  <div className="flex-column d-flex flex-md-row justify-content-between">
                    <div className="mr-md-5 pr-md-5 flex-1">
                      <h1 className="title-job d-flex align-items-center justify-content-between text-capitalize">
                        <strong>
                          {stripHtmlTags(forumDetailData?.question)}
                        </strong>
                        <span className="d-flex gap-3 align-items-center">
                          {/* <FavouriteButton  is_fav_camp={forumDetailData?.isuserfavourite==0?false:true}/> */}
                          <InlineShare
                            forumData={forumDetailData}
                            isShareActive={isShareActive}
                            setIsShareActive={setIsShareActive}
                          />
                        </span>
                      </h1>
                      <div className="d-flex mb-4 flex-md-row flex-column justify-content-between align-md-items-center">
                        <span className="text-black-50 gig-table items-center d-flex">
                          <span>
                            {nFormatter(forumDetailData?.views || 0)} views
                          </span>
                          <span className="big-dots"></span>
                          <span>{answerCount || 0} answers</span>
                          <span className="big-dots"></span>
                          <span>{questionLikeCount || 0} up votes</span>
                          {upvoteBtnLoader ? (
                            <span className="custom-loader-small ms-2 theme-color">
                              <Loadingicon />
                            </span>
                          ) : (
                            <>
                              <i
                                className={`fas fa-thumbs-up cursor-pointer ms-2 ${
                                  isLiked && "text-themecolor"
                                }`}
                                onClick={() =>
                                  handleLikeUnlikeDislike(
                                    isLiked ? "unlike" : "like",
                                    "lke"
                                  )
                                }
                              ></i>{" "}
                            </>
                          )}
                          <span className="big-dots"></span>
                          <span>{questionDislikeCount || 0} down votes</span>
                          {downvoteBtnLoader ? (
                            <span className="custom-loader-small ms-2 theme-color">
                              <Loadingicon />
                            </span>
                          ) : (
                            <>
                              <i
                                className={`fas fa-thumbs-down cursor-pointer ms-2 ${
                                  isDisliked && "text-themecolor"
                                }`}
                                onClick={() =>
                                  handleLikeUnlikeDislike(
                                    isDisliked ? "rmdislike" : "dislike",
                                    "dLike"
                                  )
                                }
                              ></i>{" "}
                            </>
                          )}
                          <span className="big-dots"></span>
                          <span>
                            {nFormatter(forumDetailData?.share_count || 0)}{" "}
                            shares
                          </span>
                          <span className="big-dots"></span>
                          <span>{formatTime(forumDetailData)}</span>
                        </span>
                      </div>
                      {/* // Next Previous Button // */}
                      <div className="flex justify-between mt-4">
                        <button
                          id="nextprevbtn"
                          className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                          style={{
                            backgroundColor: !nextPrevForumData?.prev_url
                              ? "#ccc"
                              : "#ff7200",
                          }}
                          disabled={nextPrevForumData?.prev_url ? false : true}
                          onClick={() => {
                            navigation(
                              `/forum/${params?.forumCatSlug}/${params?.forumSubCatSlug}/${nextPrevForumData?.prev_url?.slug}-${nextPrevForumData?.prev_url?.id}`
                            );
                            setIsLocationChange(!isLocationChange);
                          }}
                        >
                          {nextPrevLoader ? (
                            <div className="h-5 w-5 mr-2">
                              <Loadingicon />
                            </div>
                          ) : (
                            <>Previous</>
                          )}
                        </button>
                        <button
                          id="nextprevbtn"
                          className={` text-white px-5 py-3 bg-opacity-100 rounded disabled:bg-gray-200 font-medium disabled:text-black`}
                          style={{
                            backgroundColor: !nextPrevForumData?.next_url
                              ? "#ccc"
                              : "#ff7200",
                          }}
                          disabled={nextPrevForumData?.next_url ? false : true}
                          onClick={() => {
                            navigation(
                              `/forum/${params?.forumCatSlug}/${params?.forumSubCatSlug}/${nextPrevForumData?.next_url?.slug}-${nextPrevForumData?.next_url?.id}`
                            );
                            setIsLocationChange(!isLocationChange);
                          }}
                        >
                          {nextPrevLoader ? (
                            <div className="h-5 w-5 mr-2">
                              <Loadingicon />
                            </div>
                          ) : (
                            <>Next</>
                          )}
                        </button>
                      </div>

                      <div
                        className="w-100 text-black-60 mt-4 leads cn-desc"
                        id="questAns"
                        style={{
                          backgroundColor: "#f3eee62e",
                          borderRadius: "7px",
                        }}
                      >
                        <ForumAnswers
                          handleLikeUnlikeDislikeAnswer={
                            handleLikeUnlikeDislikeAnswer
                          }
                          modifyAnchorTags={modifyAnchorTags}
                          cData={forumDetailData}
                          answerCount={answerCount}
                          handleSubmitComment={handleSubmitComment}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="py-4 px-md-4 px-2 grid grid-cols-1 group-card card-body gap-4 mt-4">
                            <div className="heading-main position-relative my-3 overflow-hidden">
                                <h5 className="text-left bg-white pr-3 b"><strong>Comments({forumDetailData?.comments_count})</strong></h5>
                                <hr className="heading-hr mb-0" />
                            </div>
                            <TutorialsComments cData={forumDetailData} handleSubmitComment={handleSubmitComment}/>
                        </div>                        */}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ForumDetail;
