import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, slugToNormal, yearSatra } from '../../../../utils/utils';
import { getAllStreams } from '../../../../api/careernews';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getBlogCategory, getBlogListing, getBlogSubCategory, getSearchedData } from '../../../../api/blog';
import BlogCatSubCatList from '../common/catsubcat';
import BlogListItem from '../common/blogslist';
import { useSearch } from '../../../../Context/SearchContext';
function BlogsList() {

    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]); 
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const [blogSubCatId, setBlogSubCatId] = useState();
    const location = useLocation();
    const paramDatas = new URLSearchParams(location.search);
    const navigation = useNavigate();
    const { searchValue } = useSearch();

    const params = useParams();

    useEffect(() => {
        const searchTerm = paramDatas?.get('searchTerm');
        if(searchTerm){
            searchBlog(1, searchTerm);
            setSearchTerm(searchTerm);
        } else if (params?.blogSubCatSlug) {
            const bSubId = (params?.blogSubCatSlug).split('-').pop();
            setBlogSubCatId(bSubId)
            handleBlogsBySubCategory(1, bSubId);
        }
    }, [])
    useEffect(() => {
              
        const searchTerm = paramDatas?.get('searchTerm');
        if(searchTerm){
            searchBlog(1, searchTerm);
            setSearchTerm(searchTerm);
        }else{
            setSearchTerm('');
            setIsSearch(false);
            if(blogSubCatId){
            handleBlogsBySubCategory(1, blogSubCatId);
            }
        }
            
    }, [searchValue])

    // fetch all blogs by subcategory
    const handleBlogsBySubCategory = async (pageNo, sCatId) => {
        const resAllBlogSubCategory = await getBlogListing({ pageNo: pageNo, subcatId: sCatId });
        if (resAllBlogSubCategory?.success) {
            setIsNotFound(false)
            resAllBlogSubCategory?.data?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            console.log(resAllBlogSubCategory);
            if (resAllBlogSubCategory?.data?.data?.length && pageNo == 1) {
                setAllContent(resAllBlogSubCategory?.data?.data);
            } else if (resAllBlogSubCategory?.data?.data?.length && pageNo !== 1) {
                setAllContent([...allContent, ...resAllBlogSubCategory?.data?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }


    //load more blog by subcat data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        
        if (searchTerm?.length) {
            searchBlog(nxtPage, searchTerm, 'loadMore');
        }else{
            handleBlogsBySubCategory(nxtPage, blogSubCatId);
        }
        setPageNo(nxtPage);
    }



    const handleSearchBlog = (e) => {
        const searchTxt = e.target.value;
        setPageNo(1);
        setSearchTerm(searchTxt);
       
        if (searchTxt == '') {   
            setIsSearch(false);           
            setLoading(true);          
            handleBlogsBySubCategory(1, blogSubCatId);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            setIsSearch(false);
            searchBlog(1, searchTxt);
        }
    }

    const searchBlog = async (pNo, keyword, loadMore=null) => {
        !loadMore && setLoading(true);
        const resSearchBlogs = await getSearchedData({ pageNo: pNo, keyword });
        setIsSearch(true);

        if (resSearchBlogs?.success) {
            setTotalRecordFound(resSearchBlogs?.data?.total || 0)
            setIsNotFound(false)
            resSearchBlogs?.data?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resSearchBlogs?.data?.data?.length && pNo == 1) {
                setAllContent(resSearchBlogs?.data?.data);
            } else if (resSearchBlogs?.data?.data?.length && pNo !== 1) {
                setAllContent([...allContent, ...resSearchBlogs?.data?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
    }





    return (<>
       
        <div className='d-flex align-items-center justify-content-between'>
            <BreadCrumbs />
            <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handleSearchBlog} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder="Search Blog" />
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchBlog(1, searchTerm)}></i>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    {
                        !isSearch?<><div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong> Explore Top 10 Blogs in ${slugToNormal(params.typeWiseSlug)} For Latest In Knwoledge & Trends in ${yearSatra()}</strong>`} subTitle={`Our Top 10 ${slugToNormal(params?.blogSubCatSlug)} blog listing is constantly updated in 2024 with the latest content from experienced authors and working professionals, ensuring you stay up-to-date with the most relevant and cutting-edge insights. With a finger on the pulse of ${slugToNormal(params?.blogSubCatSlug)} trends, our blogs explore emerging technologies, best practices, case studies, and thought-provoking analyses that shape the Marketing domain.`} />
                        <div className='btn-group question-box question-box-card mw-220 d-flex justify-content-end'>
                            <button
                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/blogs/my-blogs')}
                            >
                                My Blogs
                            </button>
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/blogs/post-a-blog')}
                            >
                            Post A Blog
                            </button>                    
                        </div>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></>:null
                    }
                    
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allContent?.map((content, sIndex) => (
                                <BlogListItem  blogData={content} redirectionFn={() => navigation(`/blogs/detail/${content?.slug}-${content?.id}`)} key={sIndex}/>
                                ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                  
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default BlogsList;