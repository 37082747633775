import React, { useEffect, useRef, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, yearSatra } from '../../../../utils/utils';
import { getAllStreams } from '../../../../api/careernews';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import { useNavigate } from 'react-router-dom';
import BlogCatSubCatList from '../common/catsubcat';
import TutorialCategoryList from '../common/catsubcat';
import { getTutorialCategory } from '../../../../api/tutorial';
function TutorialCategory() {

    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allContent, setAllContent] = useState([]);
    const [allFilteredContent, setAllFilteredContent] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const searchBox = useRef(null);
    const navigation = useNavigate();
    

    useEffect(() => {
        handleFetchAllTutorialCategory(1);
    }, [])

    // fetch all category
    const handleFetchAllTutorialCategory = async (pageNo) => {
        const resAllTutorialCategory = await getTutorialCategory({ pageNo: pageNo });
        if (resAllTutorialCategory?.success) {
            setIsNotFound(false)
            resAllTutorialCategory?.data?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            console.log(resAllTutorialCategory);
            if (resAllTutorialCategory?.data?.data?.length && pageNo == 1) {
                setAllContent(resAllTutorialCategory?.data?.data);
                setAllFilteredContent(resAllTutorialCategory?.data?.data);
            } else if (resAllTutorialCategory?.data?.data?.length && pageNo !== 1) {
                setAllContent([...allContent, ...resAllTutorialCategory?.data?.data]);
                setAllFilteredContent([...allContent, ...resAllTutorialCategory?.data?.data]);
            } else {
                if (pageNo == 1) {
                    setAllContent([]);
                    setAllFilteredContent([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }


        setLoading(false);
        setLoadingLoadMore(false);
    }


    //load more category data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        handleFetchAllTutorialCategory(nxtPage);
        setPageNo(nxtPage);
        searchBox.current.value = '';
        setIsSearch(false)
    }

    const handleSearchCategory = (e) => {
        const searchTxt = e.target.value;
        setSearchTerm(searchTxt);
        if (searchTxt == '') {
            setIsSearch(false);           
            handleFetchAllTutorialCategory(1);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            setIsSearch(true);
            searchCategory(searchTxt);
        }
    }

    const searchCategory = (searchTxt) =>{            
            const fCategory = allContent?.filter((al, index)=>al.name.toLowerCase().includes(searchTxt.toLowerCase()) );
            setAllFilteredContent(fCategory);
            setTotalRecordFound(fCategory?.length);
            if(searchTerm?.length && !fCategory?.length){
                setIsNotFound(true)
            }else{
                setIsNotFound(false)
            }
    }




    return (<>
        <div className='d-flex align-items-center justify-content-between'>
            <BreadCrumbs />
            <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handleSearchCategory} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" placeholder={`Search Category`} ref={searchBox}/>
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchCategory(1, searchTerm)}></i>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                    {
                        !isSearch?<><div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Master the Essentials: Your Go-To Resource for Step-by-Step Tutorials</strong>`} subTitle={`Explore In-Depth Guides and Hands-On Learning to Sharpen Your Skills and Achieve Success`} />
                        <div className='btn-group question-box question-box-card mw-220 d-flex justify-content-end'>
                            <button
                                className="btn-soft mr-2 cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/tutorials/my-tutorials')}
                            >
                                My Tutorials
                            </button>
                            <button
                                className="btn-soft cursor-pointer hover:bg-themecolor text-white" style={{backgroundColor:'#ff7200'}}
                                onClick={() => navigation('/tutorials/post-a-tutorial')}
                            >
                            Post A Tutorial
                            </button>                    
                        </div>
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" /></>:null
                    }
                    
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allFilteredContent?.map((content, sIndex) => (
                                <TutorialCategoryList content={content} boxSize='col-md-3 mb-3' key={sIndex} type="content" redirectionFn={() => navigation(`/tutorials/${content?.slug}`)} />
                            ))
                        }

                    </div>
                    }
                </div>
                {
                    showLoadMore && !isNotFound ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                  
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default TutorialCategory;