import axios from "axios";
import { QUOTES_BASE_URL } from "../../utils/utils";
import { toast } from "react-toastify";

export const getFeaturedQuotes = () => { 
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${QUOTES_BASE_URL}get/featured/quotes/category/home`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};


export const getAllQuotesTopics = (params) => { 
    const { pageNo } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${QUOTES_BASE_URL}get/featured/quotes/category/viewAll?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};
export const getQuotesByCategory = (params) => { 
    const { pageNo, catId, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");      
    myHeaders.append("Authorization", `Bearer ${token}`)
    
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${QUOTES_BASE_URL}get/category/quotes/${catId}?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getQuotesByAuthor = (params) => { 
    const { pageNo, qAthrId, token } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");      
    myHeaders.append("Authorization", `Bearer ${token}`)
    
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${QUOTES_BASE_URL}get/author/quotes/${qAthrId}?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};


export const getFavouriteAuthors = () => { 
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${QUOTES_BASE_URL}get/favourite/authors/home`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getAllQuotesAuthors = (params) => { 
    const { pageNo } = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${QUOTES_BASE_URL}get/authors/viewAll?page=${pageNo}`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getLatestQuotes = (params) => { 
    const {token} = params;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
    };  
    return fetch(`${QUOTES_BASE_URL}get/latest/quotes/home`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));  
};

export const getQuotesDetail = (params) => { 
  const { quotesId, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };  
  return fetch(`${QUOTES_BASE_URL}get/quote/details/${quotesId}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getFavouritesQuotes = (params) => { 
  const { pageNo, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
  };  
  return fetch(`${QUOTES_BASE_URL}quote-favourite/list?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const bookMarkQuotes = (params) => { 
  const { quoteId, favourite, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`)
  let formData = new FormData();
  formData.append('quoteId', quoteId);
  formData.append('favourite', favourite);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${QUOTES_BASE_URL}quote-favourite/add`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const quotesCommentAndReply = (params) => { 
  const { quoteId, comment, commentId, token } = params;
  
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`)
  let formData = new FormData();
  formData.append('quoteId', quoteId);
  formData.append('comment', comment);
  formData.append('commentId', commentId || 0);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${QUOTES_BASE_URL}quote/comment`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const reportToQuote = (params) => { 
  const { quoteId, token, report_message } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`)
  let formData = new FormData();
  formData.append('quoteId', quoteId);
  formData.append('report_message', report_message);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${QUOTES_BASE_URL}quote/report`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};


export const giveRatingToQuote = (params) => { 
  const { quoteId, token, rating } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`)
  let formData = new FormData();
  formData.append('quoteId', quoteId);
  formData.append('rating', rating);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${QUOTES_BASE_URL}quote/rating`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const likeUnlikeDislike = (params) => { 
  const { quoteId, voteType, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`)
  let formData = new FormData();
  formData.append('quoteId', quoteId);
  formData.append('voteType', voteType);
  
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${QUOTES_BASE_URL}quote/vote`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};

export const getSearchedData = (params) => { 
  const { pageNo, keyword, type } = params;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  let formData = new FormData();
  formData.append('keyword', keyword);
  // formData.append('type', type);
  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };  
  return fetch(`${QUOTES_BASE_URL}quote/search?page=${pageNo}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));  
};