import React, { useEffect, useRef, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import BreadCrumbs from '../../../common/BreadCrumbs';
import { CDN_BASE_URL, ERROR_SOMETHING_WENT_WRONG, nFormatter, yearSatra } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { useNavigate } from 'react-router-dom';
import { getAllQuotesAuthors } from '../../../../api/quotes';
import TopicList from '../common/topicList';
import AuthorList from '../common/authorList';


function QuotesAuthors() {
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allQuotesAuthors, setAllQuotesAuthors] = useState([]);
    const [allFilteredQuotesAuthors, setAllFilteredQuotesAuthors] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const searchBox = useRef(null);
    const navigation = useNavigate();   
    useEffect(() => {
        handleFetchallQuotesAuthors(1);
    }, [])

    // fetch all quotes authors
    const handleFetchallQuotesAuthors = async (pageNo) => {
        const resAllQuotesAuthors = await getAllQuotesAuthors({ pageNo: pageNo });
        if (resAllQuotesAuthors?.success) {
            // console.log('authors', resAllQuotesAuthors?.data);
            setIsNotFound(false)
            resAllQuotesAuthors?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resAllQuotesAuthors?.data?.length && pageNo == 1) {
                setAllQuotesAuthors(resAllQuotesAuthors?.data);
            } else if (resAllQuotesAuthors?.data?.length && pageNo !== 1) {
                setAllQuotesAuthors([...allQuotesAuthors, ...resAllQuotesAuthors?.data]);
            } else {
                if (pageNo == 1) {
                    setAllQuotesAuthors([]);
                    setIsNotFound(true);
                }
            }
            setAllFilteredQuotesAuthors(allQuotesAuthors);
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }

        setLoading(false);
        setLoadingLoadMore(false);
    }

    // load more authors data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;        
        handleFetchallQuotesAuthors(nxtPage);
        setPageNo(nxtPage);
        
            searchBox.current.value = '';
            setIsSearch(false);
      
       
    }

    const popularCareerCats = [
        {
            name: "Joyce Meyer",
            value: "Joyce Meyer",
            redirectUrl: "#"
        },
        {
            name: "Henry Rollins",
            value: "Henry Rollins",
            redirectUrl: "#"
        },
        {
            name: "Helen Mirren",
            value: "Helen Mirren",
            redirectUrl: "#"
        },
        {
            name: "Justin Trudeau",
            value: "Justin Trudeau",
            redirectUrl: "#"
        },
        {
            name: "Helen Fisher",
            value: "Helen Fisher",
            redirectUrl: "#"
        },
        {
            name: "Maya Angelou",
            value: "Maya Angelou",
            redirectUrl: "#"
        },
        {
            name: "Helen Keller",
            value: "Helen Keller",
            redirectUrl: "#"
        },
        {
            name: "Julie Sweet",
            value: "Julie Sweet",
            redirectUrl: "#"
        },
        {
            name: "More ...",
            value: "More ...",
            redirectUrl: "#"
        }
    ];
    

    const handleSearchAuthor = (e) => {
        const searchTxt = e.target.value;
        setSearchTerm(searchTxt);
        if (searchTxt == '') {
            setIsSearch(false);
            handleFetchallQuotesAuthors(1);
            return;
        }
        if (searchTxt !== '' && e.key === "Enter") {
            setIsSearch(true);
            searchAuthor(searchTxt);
        }
    }

    const searchAuthor = (searchTxt) =>{            
            const fAuthors = allQuotesAuthors?.filter((t, index)=>t.name.toLowerCase().includes(searchTxt.toLowerCase()) );            
            setAllFilteredQuotesAuthors(fAuthors);
            setTotalRecordFound(fAuthors?.length);
    }  
    return (<>
           <div className='d-flex align-items-center justify-content-between'>
            <BreadCrumbs />
            <div className="search-boxs px-2 d-flex  align-items-center border rounded bg-white d-flex justify-content-between" style={{ marginBottom: '1rem' }}><input type="text" autocomplete="chrome-off" onKeyUp={handleSearchAuthor} className="searchInput px-md-3 px-0 form-control border-0 bg-transparent outline-0" ref={searchBox} placeholder="Search Author in List" />
                <i className="fa fa-search opacity-1 cursor-pointer" style={{ color: '#ff7200' }} onClick={() => searchAuthor(searchTerm)}></i>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                {
                    !isSearch?<>
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionDescription title={`<strong>Top 10 Popular Authors</strong>`} subTitle={`Discover the with and wisdom of men and women from all walks of life and from all ages. Read what they have to say. And enjoy it!`} />
                    </div>

                    <hr style={{ borderColor: '#ccc', marginBottom: 0 }} className="mb-4" />
                    </>:null
                }

                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }
                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <div className='row'>
                        {
                            allFilteredQuotesAuthors?.map((author, sIndex) => (
                                <AuthorList author={author} key={sIndex} type="author" boxSize={'col-md-3'} redirectionFn={()=>navigation(`/quotes/authors/${author?.id}/${author?.slug}`)}/>
                            ))
                        }
                    </div>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default QuotesAuthors;
