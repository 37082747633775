import React, { useEffect, useState } from "react";
import SectionDescription from "../../../common/sectionDescription";
import {
  ERROR_SOMETHING_WENT_WRONG,
  slugToNormal,
  yearSatra,
} from "../../../../utils/utils";
import { toast } from "react-toastify";
import notFound from "../../../../assets/images/not_found_new.png";
import { Loadingicon } from "../../../../AppIcons";
import ViewAllButton from "../../../common/viewallbutton";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ForumListItem from "../common/forumlistitem";
import { getForumList, getSearchedData } from "../../../../api/forum";
import BreadCrumbs from "../../../common/BreadCrumbs";
import { useSearch } from "../../../../Context/SearchContext";
function ForumListSearch() {
  const [pageNo, setPageNo] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);
  const [allContent, setAllContent] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [totalRecordFound, setTotalRecordFound] = useState(0);
  const navigation = useNavigate();
  const params = useParams();
  const location = useLocation();
  const paramDatas = new URLSearchParams(location.search);
  const { searchValue } = useSearch();

  useEffect(() => {
    const searchTerm = paramDatas?.get('searchTerm');
        if(searchTerm){
            searchForum(1, searchTerm);
            setSearchTerm(searchTerm);
        } 
  }, []);
  useEffect(() => {     
    const searchTerm = paramDatas?.get('searchTerm');
    if(searchTerm){
        searchForum(1, searchTerm);
        setSearchTerm(searchTerm);
    }else{
        setSearchTerm('');
        setIsSearch(false)
        navigation('/forum')
    }
        
}, [searchValue])

  

  //load more forum by sub category
  const loadMore = () => {
    setLoadingLoadMore(true);
    const nxtPage = pageNo + 1;
    searchForum(nxtPage, searchTerm, 'loadMore');  
    setPageNo(nxtPage);
  };

 
  const searchForum = async (pNo, keyword, loadMore = null) => {
    setIsSearch(false);
    !loadMore && setLoading(true);
    !loadMore && setPageNo(1);
    const resSearchForums = await getSearchedData({ pageNo:pNo, keyword });
    setIsSearch(true);
    if (resSearchForums?.success) {
      setTotalRecordFound(resSearchForums?.data?.questions?.total || 0);
      setIsNotFound(false);
      resSearchForums?.data?.questions?.next_page_url
        ? setShowLoadMore(true)
        : setShowLoadMore(false);
      if (resSearchForums?.data?.questions?.data?.length && pNo == 1) {
        setAllContent(resSearchForums?.data?.questions?.data);
      } else if (resSearchForums?.data?.questions?.data?.length && pNo !== 1) {
        setAllContent([
          ...allContent,
          ...resSearchForums?.data?.questions.data,
        ]);
      } else {
        if (pageNo == 1) {
          setAllContent([]);
          setIsNotFound(true);
        }
      }
    } else {
      toast.error(ERROR_SOMETHING_WENT_WRONG);
    }
    setLoading(false);
    setLoadingLoadMore(false);
  };

  return (
    <>
      {/* <BreadCrumbs /> */}
      <div className="d-flex align-items-center justify-content-between">
        <BreadCrumbs />
        
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
           
            {isSearch ? (
              <>
                <h6 className="text-black-50s label label-default w-100 py-2">
                  Search Result For '{searchTerm}' : {totalRecordFound} Result
                  Found
                </h6>
                <hr style={{ borderColor: "#f0f0f0", marginTop: 0 }} />
              </>
            ) : null}
            {loading ? (
              <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                  <div className="h-10 w-10">
                    <Loadingicon />
                  </div>
                </div>
              </div>
            ) : isNotFound ? (
              <div className="flex-column not-found d-flex text-center">
                <img src={notFound} className="notFoundImg" />
                <span style={{ opacity: 0.7 }}>No data found</span>
              </div>
            ) : (
              <div className="row">
                {allContent?.map((content, sIndex) => (
                  <ForumListItem
                    forumData={content}
                    redirectionFn={() =>
                      navigation(
                        `/forum/${params?.forumCatSlug}/${params?.forumSubCatSlug}/${content?.slug}`
                      )
                    }
                    key={sIndex}
                  />
                ))}
              </div>
            )}
          </div>
          {showLoadMore ? (
            <ViewAllButton
              disabled={false}
              redirectionUrl={"/"}
              handleClickFn={loadMore}
              btnText={"Load More"}
              type={"button"}
              btnClass={"loadButton card-btn-1"}
              loading={loadingLoadMore}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default ForumListSearch;
