import React, { useEffect, useState } from 'react';
import SectionDescription from '../../../common/sectionDescription';
import { ERROR_SOMETHING_WENT_WRONG, slugToNormal } from '../../../../utils/utils';
import { toast } from "react-toastify";
import notFound from '../../../../assets/images/not_found_new.png';
import { Loadingicon } from '../../../../AppIcons';
import ViewAllButton from '../../../common/viewallbutton';
import PopularCareerCategories from '../popularcategories';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getQuotesByAuthor, getQuotesByCategory, getSearchedData } from '../../../../api/quotes';
import { useAuth } from '../../../../Context/AuthContext';
import QList from '../common/quotesList';
import { useSearch } from '../../../../Context/SearchContext';


function QuotesListSearch() {
    const { globalToken } = useAuth();
    const [pageNo, setPageNo] = useState(1);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);
    const [loadingLoadMore, setLoadingLoadMore] = useState(false);
    const [allQuotes, setAllQuotes] = useState([]);
    
    const params = useParams();
    const navigation = useNavigate();   
    const [isSearch, setIsSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState();
    const [totalRecordFound, setTotalRecordFound] = useState(0);
    const location = useLocation();
    const paramDatas = new URLSearchParams(location.search);
    const { searchValue } = useSearch();

    useEffect(() => {
        const searchTerm = paramDatas?.get('searchTerm');
        if(searchTerm){
            searchQuote(1, searchTerm);
            setSearchTerm(searchTerm);
        } 
    }, [])
    useEffect(() => {
              
        const searchTerm = paramDatas?.get('searchTerm');
        if(searchTerm){
            searchQuote(1, searchTerm);
            setSearchTerm(searchTerm);
        }else{
            setSearchTerm('');
            navigation('/quotes');        
        }
            
    }, [searchValue])

  
    // load more quotes data
    const loadMore = () => {
        setLoadingLoadMore(true);
        const nxtPage = pageNo + 1;
        searchQuote(nxtPage, searchTerm, 'loadMore');      
        setPageNo(nxtPage);
    }

    
    const searchQuote = async (pNo, keyword, loadMore=null) => {
        setIsSearch(false);
        !loadMore && setLoading(true);
        !loadMore && setPageNo(1);
        const resSearchQuote = await getSearchedData({ pageNo: pNo, keyword });
        setIsSearch(true);

        if (resSearchQuote?.success) {
            setTotalRecordFound(resSearchQuote?.total || 0)
            setIsNotFound(false)
            resSearchQuote?.next_page_url ? setShowLoadMore(true) : setShowLoadMore(false);
            if (resSearchQuote?.data?.length && pNo == 1) {
                setAllQuotes(resSearchQuote?.data);
            } else if (resSearchQuote?.data?.length && pNo !== 1) {
                setAllQuotes([...allQuotes, ...resSearchQuote?.data]);
            } else {
                if (pageNo == 1) {
                    setAllQuotes([]);
                    setIsNotFound(true);
                }
            }
        } else {
            toast.error(ERROR_SOMETHING_WENT_WRONG)
        }
        setLoading(false);
        setLoadingLoadMore(false);
    }


    return (<>
        {/* <BreadCrumbs /> */}

        <div className='d-flex align-items-center justify-content-between'>
            <nav aria-label="breadcrumb" className="pt-0 px-0 pb-0 breadcrumb-card">
                <ol className="breadcrumb px-0 pt-0 breadcrumb-main-mobilea" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/' style={{ fontSize: 14, textDecoration: 'none' }}>Home</NavLink>
                    </li>    
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                    <NavLink to='/quotes' style={{ fontSize: 14, textDecoration: 'none' }}>Quotes</NavLink>
                    </li>                    
                    <li className={`breadcrumb-item capitalize rest-bread`}>
                        {
                            params?.qCatId?<NavLink to='/quotes/topics' style={{ fontSize: 14, textDecoration: 'none' }}>Topics</NavLink>:<NavLink to='/quotes/authors' style={{ fontSize: 14, textDecoration: 'none' }}>Authors</NavLink>
                        }                    
                    </li>                    
                
                    <li 
                        className={`breadcrumb-item capitalize active clamping ellipsis`}
                        >
                        {searchTerm?slugToNormal(`Search Topics for '${searchTerm}'`) : slugToNormal(params?.topicSlug || params?.athrSlug)} Quotes
                    </li>                        
                </ol>
            </nav>
           
        </div>
        
        <div className='row'>
            <div className='col-md-12'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1">
                
                    
                    {
                        isSearch ? <><h6 className="text-black-50s label label-default w-100 py-2">Search Result For '{searchTerm}' : {totalRecordFound} Result Found</h6><hr style={{ borderColor: '#f0f0f0', marginTop: 0 }} /></> : null
                    }

                    {loading ? (
                        <div className=" py-0 px-0 grid grid-cols-1 group-card card-body gap-4s">
                            <div className="w-full flex items-center justify-center mt-20 mb-20 text-themecolor min-h-[400px]">
                                <div className="h-10 w-10">
                                    <Loadingicon />
                                </div>
                            </div>
                        </div>) : isNotFound ? (
                            <div className="flex-column not-found d-flex text-center" >
                                <img src={notFound} className="notFoundImg" />
                                <span style={{ opacity: 0.7 }}>No data found</span>
                            </div>) : <>
                        {
                            allQuotes?.map((quote, sIndex) => (
                                <QList quote={quote} key={sIndex} type="quote" redirectionFn={(e)=>{e.preventDefault();e.stopPropagation(); navigation(`/quotes/detail/${quote?.id}/${quote?.slug}`)}}/>
                            ))
                        }
                    </>
                    }
                </div>
                {
                    showLoadMore ? <ViewAllButton
                        disabled={false}
                        redirectionUrl={'/'}
                        handleClickFn={loadMore}
                        btnText={'Load More'}
                        type={'button'}
                        btnClass={'loadButton card-btn-1'}
                        loading={loadingLoadMore}
                    /> : null
                }

            </div>
            {/* <div className='col-md-3'>
                <div className="pb-4 px-2 px-md-4 grid grid-cols-1 group-card card-body gap-1 position-sticky" style={{ top: 80 }}>
                    <PopularCareerCategories catsList={popularCareerCats} title={`Popular Categories`}/>
                </div>
            </div> */}
        </div>

    </>)
}

export default QuotesListSearch;
