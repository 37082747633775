import { BASE_URL, QBANK_BASE_URL } from "../utils/utils";
import { axiosReq as axios } from "./axios";

export const LoginApi = async (details) => {
    return axios.post(`${BASE_URL}user/login`, details );
}

export const RedirectApi = (details) => {
    return axios.post(`${BASE_URL}user/redirection-login`, details );
}
export const RegisterApi = (details) => {
    return axios.post(`${BASE_URL}user/registeration`, details );
}

export const ForgotPasswordApi = (details) => {
    return axios.post(`${BASE_URL}user/forgotpassword`, details );
}

export const sendEmailToVerifyApi = (details) => {
    const baseURL = BASE_URL;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Access-Control-Allow-Credentials', 'true');
    myHeaders.append("Authorization", `Bearer ${details?.token}`);
  
    let requestBody = JSON.stringify({
      email: details?.email
    }); 
  
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: requestBody
    };
    return fetch(`${baseURL}user/send-verification-mail`, requestOptions)
    .then(async (response) => {
      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (error) {
        // Handle the non-JSON response here (e.g., log the error)
        console.log("Non-JSON response:", text);
        throw new Error("Invalid JSON response from the server");
      }
    })
    .catch((error) => console.log("Error:", error));
    // return axios.post(`${BASE_URL}user/send-verification-mail`, details );
}

export const verifyEmailApi = (details) => {
  const baseURL = BASE_URL;
        let myHeaders = new Headers();
        myHeaders.append("Accept", "*/*");        
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Access-Control-Allow-Credentials', 'true');
        myHeaders.append("Authorization", `Bearer ${details?.accessToken}`);


        let requestBody = JSON.stringify({
          email: details?.email,
          token:details?.token
        }); 

        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: requestBody
        };
        return fetch(`${baseURL}user/verify-verification-code`, requestOptions)
        .then(async (response) => {
          const text = await response.text();
          try {
            return JSON.parse(text);
          } catch (error) {
            console.log("Non-JSON response:", text);
            throw new Error("Invalid JSON response from the server");
          }
        })
        .catch((error) => console.log("Error:", error));
    // return axios.post(`${BASE_URL}user/verify-verification-code`, details );
}

export const userVerificationApi = (details) => {
        const baseURL = BASE_URL;
        let myHeaders = new Headers();
        myHeaders.append("Accept", "*/*");
        myHeaders.append("Authorization", `Bearer ${details?.token}`);

        let requestBody = JSON.stringify({
          email: details?.email
        }); 

        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: requestBody
        };
        return fetch(`${baseURL}user/send-verification-mail`, requestOptions)
        .then(async (response) => {
          const text = await response.text();
          try {
            return JSON.parse(text);
          } catch (error) {
            console.log("Non-JSON response:", text);
            throw new Error("Invalid JSON response from the server");
          }
        })
        .catch((error) => console.log("Error:", error));
    // return axios.post(`${BASE_URL}user/get-verification-status`, details );
}

export const userProfileUpdate = (details) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Authorization", `Bearer ${details?.token}`);

  // Create FormData
  let formData = new FormData();
  if(details?.address){
    formData.append("address", details?.address);
  }
  if(details?.country){
    formData.append("country", details?.country);
  }
    if(details?.state){
      formData.append("state", details?.state);
    }
    if(details?.city){
    formData.append("city", details?.city);
}
    if(details?.postal_code){
    formData.append("postal_code", details?.postal_code);
    }
    
  if(details.image){
    formData.append('image', details.image)
  }else if(details.cover_image){
    formData.append('cover_image', details.cover_image)
  }else{
    formData.append("name", details?.name);
    formData.append("country_code", details?.ccode);
    formData.append("phone", details?.phone);
    formData.append("email", details?.email);
    formData.append("dob", details?.dob);
    formData.append("about", details?.about);
    formData.append("fatherName", details?.fatherName);
    formData.append("motherName", details?.motherName);
    formData.append("gender", details?.gender);
    formData.append("is_private", details?.isPrivate)
  }
  // Add more fields as needed

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData, // Use FormData instead of JSON.stringify
  };

  // console.log(formData); // FormData doesn't log like JSON.stringify, you can iterate through it to see its content

  return fetch(`${baseURL}user/update-profile`, requestOptions)
    .then(async (response) => {
      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (error) {
        console.log("Non-JSON response:", text);
        throw new Error("Invalid JSON response from the server");
      }
    })
    .catch((error) => console.log("Error:", error));
};


export const userAddressUpdate = (details) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Authorization", `Bearer ${details?.token}`);

  // Create FormData
  let formData = new FormData();
  
  
    formData.append("address", details?.address);
    formData.append("country", details?.country);
    if(details?.state){
      formData.append("state", details?.state);
    }
    
    formData.append("city", details?.city);
    formData.append("postal_code", details?.postal_code);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData, // Use FormData instead of JSON.stringify
  };


  // console.log(formData); // FormData doesn't log like JSON.stringify, you can iterate through it to see its content

  return fetch(`${baseURL}user/update-profile`, requestOptions)
    .then(async (response) => {
      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (error) {
        console.log("Non-JSON response:", text);
        throw new Error("Invalid JSON response from the server");
      }
    })
    .catch((error) => console.log("Error:", error));
};

export const plansWithTax = (params) => {
  const { token, amount_in_rupees} = params;
  
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Authorization", `Bearer ${token}`);

  // Create FormData
  let formData = new FormData();
    formData.append("amount_in_rupees", amount_in_rupees);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData
  };

  return fetch(`${baseURL}get-final-amount-for-credits-purchase`, requestOptions)
    .then(async (response) => {
      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (error) {
        console.log("Non-JSON response:", text);
        throw new Error("Invalid JSON response from the server");
      }
    })
    .catch((error) => console.log("Error:", error));
}


export const onProfileVerification = (details) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Authorization", `Bearer ${details?.token}`);

  // Create FormData
  let formData = new FormData();  
    formData.append("id_type", details?.id_type);
    formData.append("id_photo", details?.id_photo);
    formData.append("selfie", details?.selfie);
  
  // Add more fields as needed

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData, // Use FormData instead of JSON.stringify
  };


  return fetch(`${baseURL}user/send-profile-verification-request`, requestOptions)
    .then(async (response) => {
      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (error) {
        console.log("Non-JSON response:", text);
        throw new Error("Invalid JSON response from the server");
      }
    })
    .catch((error) => console.log("Error:", error));
};

export const onProfileVerificationStatus = (details) => {
    const baseURL = BASE_URL;
    let myHeaders = new Headers();
    myHeaders.append("Accept", "*/*");
    myHeaders.append("Authorization", `Bearer ${details?.token}`);
  
  
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
    };
  

  return fetch(`${baseURL}user/check-profile-verification-status`, requestOptions)
    .then(async (response) => {
      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (error) {
        console.log("Non-JSON response:", text);
        throw new Error("Invalid JSON response from the server");
      }
    })
    .catch((error) => console.log("Error:", error));
};


export const onNewTicketRaise = (details) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Authorization", `Bearer ${details?.token}`);

  // Create FormData
  let formData = new FormData();  
    formData.append("module", details?.module || '');
    formData.append("subject", details?.subject);
    formData.append("message", details?.message);
  
  // Add more fields as needed

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData, // Use FormData instead of JSON.stringify
  };


  return fetch(`${baseURL}user/help-support`, requestOptions)
    .then(async (response) => {
      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (error) {
        console.log("Non-JSON response:", text);
        throw new Error("Invalid JSON response from the server");
      }
    })
    .catch((error) => console.log("Error:", error));
};

export const onNewLinkAdd = (details) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Authorization", `Bearer ${details?.token}`);

  // Create FormData
  let formData = new FormData();  
    formData.append("link_name", details?.link_name || '');
    formData.append("link_address", details?.link_address || '');
    formData.append("type", details?.type || '');
  
  // Add more fields as needed

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData, // Use FormData instead of JSON.stringify
  };


  return fetch(`${baseURL}user/save-user-link`, requestOptions)
    .then(async (response) => {
      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (error) {
        console.log("Non-JSON response:", text);
        throw new Error("Invalid JSON response from the server");
      }
    })
    .catch((error) => console.log("Error:", error));
};


export const onLinkDelete = (details) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Authorization", `Bearer ${details?.token}`);

  // Create FormData
  let formData = new FormData();  
    formData.append("link_id", details?.link_id || '');
  // Add more fields as needed

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData, // Use FormData instead of JSON.stringify
  };


  return fetch(`${baseURL}user/delete-user-link`, requestOptions)
    .then(async (response) => {
      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (error) {
        console.log("Non-JSON response:", text);
        throw new Error("Invalid JSON response from the server");
      }
    })
    .catch((error) => console.log("Error:", error));
};

export const onRaisedTicketsList = (details) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append('Access-Control-Allow-Credentials', 'true');
  myHeaders.append("Authorization", `Bearer ${details?.token}`);

  // Add more fields as needed

  let requestOptions = {
    method: "POST",
    headers: myHeaders
  };

  return fetch(`${baseURL}user/get-help-requests`, requestOptions)
    .then(async (response) => {
      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (error) {
        console.log("Non-JSON response:", text);
        throw new Error("Invalid JSON response from the server");
      }
    })
    .catch((error) => console.log("Error:", error));
};


export const onMyLinksList = (details) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append('Access-Control-Allow-Credentials', 'true');
  myHeaders.append("Authorization", `Bearer ${details?.token}`);

  // Add more fields as needed

  let requestOptions = {
    method: "POST",
    headers: myHeaders
  };

  return fetch(`${baseURL}user/get-user-links`, requestOptions)
    .then(async (response) => {
      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (error) {
        console.log("Non-JSON response:", text);
        throw new Error("Invalid JSON response from the server");
      }
    })
    .catch((error) => console.log("Error:", error));
};


export const onRecentActivityList = (details) => {
  const baseURL = BASE_URL;
  let myHeaders = new Headers();
  myHeaders.append("Accept", "*/*");
  myHeaders.append("Authorization", `Bearer ${details?.token}`);

  const formData = new FormData();
  formData.append('module', details?.choosenModule!==''?details?.choosenModule:'');
  formData.append('per_page', 3000);
  formData.append('page_no', 1);
  formData.append('no_of_days', details?.filterDays);

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formData,
  };

  return fetch(`${baseURL}user/get-activities-list`, requestOptions)
    .then(async (response) => {
      const text = await response.text();
      try {
        return JSON.parse(text);
      } catch (error) {
        console.log("Non-JSON response:", text);
        throw new Error("Invalid JSON response from the server");
      }
    })
    .catch((error) => console.log("Error:", error));
};


export const ResetPasswordApi = (details) => {
    return axios.post(`${BASE_URL}user/setnewpassword`, details );
}

export const SocialLoginApi = (details) => {
    return axios.post(`${BASE_URL}user/sociallogin`, details );
}